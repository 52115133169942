<template>
	<div class="mainpage register_page">
		<van-nav-bar
		  title=""
		  left-text=""
		  left-arrow
		  fixed
		  placeholder 
		  @click-left="$u.back"
		/>
		<div class="register_content">
			<div class="welcome_wrap">
				<div class="login_title">
					<div class="title1">手机注册</div>
				</div>
			</div>
			<div class="form_wrap">
				<div class="form_ipt_wrap">
					<div class="form_ipt">
						<input placeholder="输入您的手机号码" type="number" maxlength="11" class="font25" v-model="phone"/>
					</div>
					<div class="form_ipt">
						<input placeholder="输入您的登录密码" maxlength="25" type="password" class="font25" v-model="password"/>
					</div>
					<div class="form_ipt">
						<input placeholder="确认密码" maxlength="25" type="password" class="font25" v-model="repass"/>
					</div>
					<div class="form_ipt">
						<input placeholder="输入您的昵称" maxlength="25" class="font25" v-model="username"/>
					</div>
					<div class="form_ipt" style="padding:0.25rem 0.5rem;">
						<input placeholder="输入验证码" class="font25" type="text" maxlength="4" v-model="yzm"/>
						<div>
							<vercode ref="yzm"/>
						</div>
					</div>
				</div>
				<div class="form_footer">
					<van-button type="info" :loading="isloading" loading-text="注册中···" block class="log_btn" @click="login">注册</van-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import vercode from "@/components/vercode.vue";
	export default{
		components:{vercode},
		data(){
			return{
				active:0,
				username:"",
				password:"",
				repass:"",
				phone:"",
				yzm:"",
				isloading:false,
			}
		},
		mounted() {
			
		},
		methods:{
			login(){
				/* const isValid = /^[A-Za-z0-9]{6,}$/.test(this.username);
				if (!isValid) {
					return this.$u.toast("请输入6位及以上英文数字组合账号")
				} */
				if(this.phone==''){
					return this.$u.toast('请输入手机号');
				}
				if(this.phone.length!=11){
					return this.$u.toast("请输入11位手机号")
				}
				if(this.password==''){
					return this.$u.toast('请输入密码');
				}
			    const ispassValid = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,20}$/.test(this.password);
				if (!ispassValid) {
					return this.$u.toast("请输入8-20个字母数字组合密码")
				}
				if(this.repass!=this.password){
					return this.$u.toast('两次密码输入不一致');
				}
				if(this.username==''){
					return this.$u.toast('请输入昵称');
				}
				if(this.yzm==''){
					return this.$u.toast('请输入验证码');
				}
				if(!this.$refs.yzm.checkcode(this.yzm)){
					return this.$u.toast('验证码错误');
				}
				let formdata={
					username:this.username,
					password:this.password,
					repass:this.repass,
					phone:this.phone
				}
				this.isloading=true;
				this.$u.http("/api/index/register",{body:formdata}).then(res=>{
					this.isloading=false;
					this.$u.toast(res.msg);
					if(res.status==200){
						this.$u.setitem('$uinfo',res.data);
						this.$router.replace('/person');
					}else{
						this.$refs.yzm.generateCode();
					}
				}).catch(err=>{
					this.isloading=false;
					this.$refs.yzm.generateCode();
				})
			},
			toregister(){
				this.$u.to("/register");
			}
		}
	}
</script>

<style lang="scss">
	.register_page{
		height: 100vh;
		position: relative;
		padding: 0 1rem 0;
		background: url(../../assets/imgs/loginbg.png) no-repeat;
		background-size: 100% auto;
		background-position: 0 1.92rem;
	}
	.register_content{
		.form_wrap{
			margin-top: 1rem;
			border-radius: 16px;
			background-color: #fff;
			padding: 10px;
			.form_footer{
				margin-top: 2rem;
				.log_btn{
					 background: linear-gradient(180deg, $mcolor1, $mcolor2);
					 border-color: $mcolor1;
					 font-size: 0.8rem;
					 border-radius: 10px;
					 height: 50px;
				}
				.re_txt{
					text-align: center;
					padding: 0.5rem 0;
				}
			}
			.form_ipt_wrap{
				.form_ipt{
					display: flex;
					padding:0.65rem 0.5rem;
					border-radius: 10px;
					background-color: #f5f5f9;
					margin-bottom: 10px;
					position: relative;
					.yzm_wrap{
						position: absolute;
						right: 10px;
						top: 10px;
						background-color: #fff;
						border-radius: 10px;
						padding: 6px 16px;
					}
					input{
						border: none;
						outline: none;
						background-color: transparent;
						flex: 1;
						width: 70%;
						color: #555;
						&::placeholder{
							color: #ccc;
						}
					}
				}
			}
		}
		.welcome_wrap{
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-top: 2rem;
			.login_title{
				.title1{
					font-size: 1.1rem;
					font-weight: bold;
					color: #333;
				}
				.title2{
					font-size: 0.85rem;
					font-weight: bold;
					color: #333;
					margin-top: 0.5rem;
				}
			}
			.wel_logo{
				width: 110px;
				height: 110px;
			}
		}
	}
</style>