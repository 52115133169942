<template>
	<div class="mainpage" style="height: auto;">
		<van-nav-bar
		  title="资料大全"
		  left-text=""
		  left-arrow
		  fixed
		  placeholder 
		  @click-left="$u.back"
		/>
		<div style="position: sticky;top: 2rem;z-index: 11;">
			<van-search
			  v-model="kw"
			  show-action
			  label=""
			  placeholder="搜索相关标题文章"
			  @search="onSearch"
			>
			  <template #action>
			    <div @click="onSearch">搜索</div>
			  </template>
			</van-search>
		</div>
		<div class="imglist_list">
			<van-pull-refresh v-model="refreshing" @refresh="onRefresh">
				<van-list
				  v-model="loading"
				  :finished="finished"
				  finished-text="没有更多了"
				  @load="loadmore"
				>
				  <div class="data_list">
					  <div class="data_list_left">
						  <div class="data_item font25" v-for="(item,index) in list" :key="index" @click="todetail(item)" v-if="index%2==0">
							  <van-image
							    width="100%"
							    height="auto"
							    :src="item.img"
							  />
							  <div class="data_name">{{item.name}}</div>
						  </div>
					  </div>
					  <div class="data_list_right">
						  <div class="data_item font25" v-for="(item,index) in list" :key="index" @click="todetail(item)" v-if="index%2==1">
						  		<van-image
						  		  width="100%"
						  		  height="auto"
						  		  :src="item.img"
						  		/>
						  		<div class="data_name">{{item.name}}</div>				  
						  </div>
					  </div>
				  </div>
				</van-list>
			</van-pull-refresh>
		</div>
	</div>
</template>

<script>
	import Vue from 'vue';
	import { Search } from 'vant';
	Vue.use(Search);
	import datalistmixin from '@/assets/mixin/datalist.js';
	export default{
		mixins: [datalistmixin],
		data(){
			return{
				apiurl:'/index/tuku/getdatalist',
				query:{
					type:2
				},
			}
		},
		mounted() {
			
		},
		methods:{
			todetail(item){
				this.$u.to("/imgdetail/"+item.id);
			},
			onSearch(){
				this.page=1;
				this.list=[];
				this.getdatalist();
			},
			
		}
	}
</script>

<style lang="scss">
	.imglist_list{
		background-color: inherit;
		.data_list{
			display: flex;
			gap: 0.4rem;
			padding: 10px;
			.data_list_left{
				width: calc(50% - 0.2rem);
			}
			.data_list_right{
				width: calc(50% - 0.2rem);
			}
			.data_item{
				text-align: center;
				width: 100%;
				background-color: #fff;
				border-radius: 5px;
				margin-bottom: 10px;
				.data_name{
					padding: 5px;
				}
			}
		}
	}
</style>