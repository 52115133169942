<template>
	<div class="mainpage">
		<van-nav-bar
		  title="官方消息"
		  left-text=""
		  left-arrow
		  fixed
		  placeholder 
		  @click-left="$u.back"
		/>
		<div class="official_page">
			<van-pull-refresh v-model="refreshing" @refresh="onRefresh">
				<van-list
				  v-model="loading"
				  :finished="finished"
				  finished-text="没有更多了"
				  @load="loadmore"
				>
				  <div class="msg_list">
					  <div class="msg_item" v-for="(item,index) in list" :key="index">
						  <div class="msg_tag" :class="{tag_over:isshixiao(item)==2}">{{isshixiao(item)==1?'未结束':'已结束'}}</div>
						  <div class="msg_title">{{item.title}}</div>
						  <div class="msg_content">
						  		<span v-html="item.content" style="white-space: pre-wrap;"></span>
						  </div>
						  <div class="msg_time">有效时间: {{parsetime(item.starttime)}}-{{parsetime(item.endtime)}}</div>
					  </div>
				  </div>
				</van-list>
			</van-pull-refresh>
		</div>
	</div>
</template>

<script>
	import Vue from 'vue';
	import datalistmixin from '@/assets/mixin/datalist.js';
	export default{
		mixins: [datalistmixin],
		data(){
			return{
				apiurl:"/api/index/getnotice",
				query:{
					type:'1'
				}
			}
		},
		mounted() {
			
		},
		methods:{
			isshixiao(item){
				let todayLastSecond = new Date();
				todayLastSecond.setHours(23, 59, 59, 999);
				let timestamp = Math.floor(todayLastSecond.getTime());
				if(timestamp>(+item.endtime+86400)){
					return 2
				}else{
					return 1
				}
			},
			parsetime(time){
				return this.$u.parseTime(time,'{y}/{m}/{d}');
			}
		}
	}
</script>

<style lang="scss">
	.official_page{
		padding: 10px;
		background-color: #f2f4f7;
		.msg_list{
			.msg_item{
				background-color: #fff;
				border-radius: 0.2rem;
				position: relative;
				margin-bottom: 10px;
				.msg_tag{
					position: absolute;
					top: 0;
					right: 0;
					padding:0.2rem 0.4rem ;
					border-radius: 0.1rem 0.5rem;
					background: #07c165;
					color: #fff;
					font-size: 0.5rem;
				}
				.tag_over{
					background: #bbb;
				}
				.msg_title{
					color:$mcolor1;
					padding: 0.5rem;
					font-size: 0.75rem;
					font-style: normal;
					font-weight: 600;
				}
				.msg_content{
					padding: 0.5rem;
					padding-top: 0;
					color: #333;
					font-size: 0.65rem;
				}
				.msg_time{
					padding: 0.4rem 0.5rem;
					font-size: 0.55rem;
					color: #888;
					border-top: 1px solid #ddd;
				}
			}
		}
	}
</style>