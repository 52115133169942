<template>
	<div class="mainpage">
		<van-nav-bar
		  title="关于我们"
		  left-text=""
		  left-arrow
		  fixed
		  placeholder 
		  @click-left="$u.back"
		/>
		<div class="about_list">
			<div class="data_item">
				<span class="data_item_label">QQ:</span>
				<div class="data_item_content">{{configdata.aboutdata1||''}}</div>
			</div>
			<div class="data_item">
				<span class="data_item_label">官网:</span>
				<div class="data_item_content">{{configdata.aboutdata2||''}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Vue from 'vue';
	import {  Cell, CellGroup } from 'vant';
	
	Vue.use(Cell);
	Vue.use(CellGroup);
	export default{
		data(){
			return{
				configdata:{}
			}
		},
		mounted() {
			this.$u.http('/api/index/getconfigval',{body:{key:"aboutdata1,aboutdata2"}}).then(res=>{
				this.configdata=res.msg;
			})
		},
		methods:{
			
		}
	}
</script>

<style lang="scss">
	.about_list{
		background-color: #fff;
		.data_item{
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0.6rem;
			&:not(:last-child){
				border-bottom: 1px solid #e5e5e5;
			}
			.data_item_label{
				font-size: 0.8rem;
				 color: #555;
			}
			.data_item_content{
				flex: 1;
				width: 10px;
				text-align: right;
			}
		}
	}
</style>