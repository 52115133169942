<template>
	<div class="vote_list">
		<van-row gutter="20">
		  <van-col span="12" v-for="(item,index) in votelist">
			  <div class="vote_num">{{item.num}}票</div>
			  <div class="vote_pro">
					<div class="vote_name">{{item.name}}</div>
					<div class="bd-rate">
						<div class="bd_rate_color" :style="{width:item.rate+'%'}"></div>
					</div>
					<div class="vote_bfb">{{item.rate}}%</div>
			  </div>
		  </van-col>
		</van-row>
	</div>
</template>

<script>
	export default{
		props:['imgdetail'],
		data(){
			return{
				votearr:['鼠', '牛', '虎', '兔', '龙', '蛇', '马', '羊', '猴', '鸡', '狗', '猪']
			}
		},
		computed:{
			votelist(){
				let arr=[];
				let total=0;
				for(let i=0;i<this.votearr.length;i++){
					total+=this.imgdetail['votenum'+(i+1)];
					arr.push({
						name:this.votearr[i],
						num:this.imgdetail['votenum'+(i+1)],
					})
				}
				arr.map(item=>{
					if(total!=0){
						item.rate = ((item.num/total)*100).toFixed(2);
					}else{
						item.rate = '0.00';
					}
				});
				return arr
			}
		},
		created() {
			
		},
		methods:{
			
		}
	}
</script>

<style lang="scss">
	.vote_list{
		padding: 10px;
		color: #333;
		.vote_num{
			text-align: center;
			font-size: 0.55rem;
			margin: 5px 0;
		}
		.vote_pro{
			display: flex;
			align-items: center;
			font-size: 0.55rem;
			margin-bottom: 10px;
			.vote_name{
				width: 0.8rem;
			}
			.bd-rate{
				flex: 1;
				width: 50%;
				height: 12.8px;
				position: relative;
				background: #ebeae8;
				.bd_rate_color{
					position: absolute;
					left: 0;
					top: 0;
					height: 100%;
					width: 12.4%;
					background: #3e73fe;
				}
			}
			.vote_bfb{
				width: 2.2rem;
				text-align: right;
			}
		}
	}
</style>