<template>
	<div class="mainpage" style="height: auto;">
		<van-nav-bar
		  left-text=""
		  left-arrow
		  fixed
		  placeholder 
		  @click-left="$u.back"
		>
			<template #title>
				<div style="width: 100%;overflow: hidden;white-space: nowrap;font-size: 0.7rem;">
					{{imgdetail.imgname||''}}
				</div>
			</template>
			<template #right>
				<div class="top_rbtn" style="margin-right: 0.5rem;" @click="showHistory">
					<van-icon name="clock-o" size="18"/>
					<span>开奖记录</span>
				</div>
				<div class="top_rbtn" @click="addcomment">
					<van-icon name="edit" size="18"/>
					<span>发表评论</span>
				</div>
			</template>
		</van-nav-bar>
		<periodsFilter :id="id" :qi="qi" :year="year"  @selqishu="selqishu" v-if="id"/>
		<drawitem :qi="qi"/>
		<div class="img_detail">
			<div class="main_img_wrap">
				<van-image
				  width="100%"
				  height="auto"
				  style="min-height: 200px;"
				  :src="imgdetail.img"
				  @click="viewdetailimg"
				 >
					<template v-slot:loading>
						<div style="text-align: center;">
							<div><van-icon name="photo-o" size="100" color="#ccc"/></div>
							<van-loading type="circular" size="32" color="#777"/>
						</div>
					</template>
				</van-image>
			</div>
			<div class="vote_wrap" v-if="isshowvote">
				<voteItem :imgdetail="imgdetail"/>
				<div class="vote_btn_wrap">
					<van-button type="info" round block class="vote_btn" @click="showvote">投票</van-button>
					<van-button type="info" plain round block class="vote_btn" @click="tovotelist">投票记录</van-button>
				</div>
			</div>
			<div class="open-hide font2" @click="isshowvote=!isshowvote">
				{{isshowvote?'收起投票':'展开投票'}}
				<van-icon name="arrow-down" size="16" v-if="!isshowvote"/>
				<van-icon name="arrow-up" size="16" v-if="isshowvote"/>
			</div>
			<div class="pd12">
				<div class="img_num_wrap">
					<van-row>
					  <van-col span="4" style="border-right: 1px solid #eee;">
						  <div class="num font4">{{imgdetail.likenum||0}}</div>
						  <div class="label font2">点赞</div>
					  </van-col>
					  <van-col span="5" style="border-right: 1px solid #eee;">
						  <div class="num font4">{{imgdetail.totalComments||0}}</div>
						  <div class="label font2">评论</div>
					  </van-col>
					  <van-col span="5" style="border-right: 1px solid #eee;">
						  <div class="num font4">{{imgdetail.follownum||0}}</div>
						  <div class="label font2">关注</div>
					  </van-col>
					  <van-col span="5" style="border-right: 1px solid #eee;">
						  <div class="num font4">{{imgdetail.colnum||0}}</div>
						  <div class="label font2">收藏</div>
					  </van-col>
					  <van-col span="5">
						  <div class="num font4">{{imgdetail.viewnum||0}}</div>
						  <div class="label font2">阅读</div>
					  </van-col>
					</van-row>
				</div>
			</div>
			<div class="pd12">
				<div class="img_recomand">
					<div class="font4 img_retitle">  -  图纸推荐  -  </div>
					<div class="img_list">
						<div class="img_item" v-for="(item,index) in recommendList" :key="index" @click='selimg(item)'>
							<van-image
							  width="100%"
							  height="auto"
							  :src="item.img"
							 >
								<template v-slot:loading>
									<van-loading type="circular" size="22" />
								</template>
							</van-image>
							<div class="img_name">{{item.name}}</div>
						</div>
					</div>
				</div>
			</div>
			<div>
				<van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
				  <van-swipe-item v-for="item in slider">
				    <img style="height: 100%; width: 100%" :src="item.img" alt="">
				  </van-swipe-item>
				</van-swipe>
			</div>
			<div class="pd12">
				<div class="site_wrap">
					<div class="font4 site_title">  -  网址大全  -  </div>
					<sitelist/>
				</div>
			</div>
			<div class="pd12">
				<div class="comment_wrap">
					<div class="details-title_wrap">
						<div class="details-title">热门评论</div>
					</div>
					<div class="title-bk"></div>
					<div class="comment_list">
						<commentItem key="111" :datalist="hotcomments" :uid="uid" v-if="hotcomments.length!=0" @showlogin="showlogin"/>
						<van-empty description="成为第一个评论的人" v-if="hotcomments.length==0"/>
					</div>
				</div>
				<div class="comment_wrap">
					<div class="details-title_wrap">
						<div class="details-title">最新评论</div>
					</div>
					<div class="title-bk"></div>
					<div class="comment_list">
						<commentItem key="222" :datalist="comments" :uid="uid" v-if="comments.length!=0" @showlogin="showlogin"/>
						<van-empty description="成为第一个评论的人" v-if="comments.length==0"/>
					</div>
				</div>
			</div>
		</div>
		<div class="bot_wrap">
			<div class="comment_form">
				<van-row>
				  <van-col span="21">
					  <div class="ipt_wrap" @click="addcomment">来发布您的评论吧</div>
				  </van-col>
				  <van-col span="3">
					  <div class="comment_num">
						  <van-icon name="chat-o" size="1.1rem"/>
						  <div class="font2">{{imgdetail.totalComments||0}}</div>
					  </div>
				  </van-col>
				</van-row>
			</div>
			<div class="btn_list">
				<div class="btn_item" @click="addcomment">
					<van-icon name="chat" size="1rem" color="#3e73fe" />
					<div>评论</div>
				</div>
				<div class="btn_item" @click="useraction('col')">
					<van-icon name="label-o" size="1rem" :color="imgdetail.col?'#3e73fe':''"/>
					<div>{{imgdetail.col?'已':''}}收藏</div>
				</div>
				<div class="btn_item" @click="useraction('like')">
					<van-icon name="good-job-o" size="1rem" :color="imgdetail.like?'#3e73fe':''"/>
					<div>{{imgdetail.like?'已':''}}点赞</div>
				</div>
				<div class="btn_item" @click="shareurl">
					<van-icon name="share-o" size="1rem"/>
					<div>转发</div>
				</div>
			</div>
		</div>
		<addcomment ref="addcomment" @completereply="completereply"/>
		
		<van-popup v-model="isshowvotepop" closeable position="center" round style="width: 90%; max-width: 400px;">
			<div class="vote_pop">
				<div class="vote_title">第{{imgdetail.qi||''}}期</div>
				<van-row  gutter="8" style="padding: 20px 0;">
				  <van-col span="6" v-for="(item,index) in votearr">
					  <div class="vote_item " :class="{active_item:curvote===index}" @click="curvote=index">
						  <div>{{item}}</div>
						  <div class="font2" style="color: #7e7e7e;">{{imgdetail['votenum'+(index+1)]}}票</div>
					  </div>
				  </van-col>
				</van-row>
				<van-button type="info" block @click="dovote">投票</van-button>
			</div>
		</van-popup>
	</div>
</template>

<script>
	import drawitem from '@/components/drawitem.vue';
	import periodsFilter from '../../components/periodsFilter.vue';
	import voteItem from '../../components/vote-item.vue';
	import sitelist from '../../components/sitelist.vue';
	import addcomment from '../../components/addcomment.vue';
	import commentItem from '../../components/commentItem.vue';
	import Vue from 'vue';
	import { DropdownMenu, DropdownItem } from 'vant';
	import { Col, Row } from 'vant';
	import { Image as VanImage } from 'vant';
	import { Popup } from 'vant';
	import { ImagePreview } from 'vant';
	import { Loading } from 'vant';
	import { Empty } from 'vant';

	Vue.use(Empty);
	Vue.use(Loading);
	Vue.use(Popup);
	Vue.use(VanImage);
	Vue.use(Col);
	Vue.use(Row);
	Vue.use(DropdownMenu);
	Vue.use(DropdownItem);
	export default{
		components:{periodsFilter,voteItem,sitelist,drawitem,addcomment,commentItem},
		data(){
			return{
				curvote:'',
				isshowvotepop:false,
				votearr:['鼠', '牛', '虎', '兔', '龙', '蛇', '马', '羊', '猴', '鸡', '狗', '猪'],
				yearlist:[
					{name:"2024",id:1},
					{name:"2023",id:1},
					{name:"2022",id:1},
					{name:"2021",id:1},
					{name:"2020",id:1},
				],
				qishulist:50,
				curqishu:1,
				isshowvote:false,
				loading:false,
				message:"",
				recommendList:[],
				imgdetail:{},
				id:'',
				qi:"",
				year:"",
				pid:"",
				hotcomments:[],
				uid:"",
				comments:[],
				slider:[]
			}
		},
		beforeRouteEnter:(to, from, next) => {
			var mymeta = document.querySelector('meta[name="theme-color"]');
			if (mymeta) {
				mymeta.remove();
			}
			var meta = document.createElement('meta');
			meta.setAttribute('name', 'theme-color');
			meta.setAttribute('content', '#dc0002');
			document.head.appendChild(meta);
			next();
		},
		beforeRouteLeave:(to, from, next) => {
			var mymeta = document.querySelector('meta[name="theme-color"]');
			if (mymeta) {
				mymeta.remove();
			}
		    next();
		},
		watch: {
		    '$route'(to, from) {
				if(to.params.id){
					this.id=to.params.id;
					this.$u.totop();
					this.getimgdetail();
				}
		    }
		},
		mounted() {
			if(this.$route.params.id){
				this.id=this.$route.params.id;
				if(this.$route.params.pid){
					this.pid=this.$route.params.pid;
				}
				this.getimgdetail();
					this.$u.totop();
			}
		},
		methods:{
			showlogin(){
				this.$parent.showlogin();
			},
			shareurl(){
				this.$u.copy(location.href);
			},
			showvote(){
				let uinfo=this.$u.getitem('$uinfo')||{};
				if(!uinfo.token){
					this.$parent.showlogin();
					return;
				}
				this.isshowvotepop=true;
			},
			dovote(){
				let uinfo=this.$u.getitem('$uinfo')||{};
				if(!uinfo.token){
					this.$parent.showlogin();
					return;
				}
				if(this.curvote===''){
					return;
				}
				let formdata={
					id:this.imgdetail.id,
					curvote:this.curvote+1,
					token:uinfo.token,
				}
				this.$u.http("/api/getimg/dopicvote",{body:formdata}).then(res=>{
					this.isshowvotepop=false;
					this.curvote='';
					this.$u.toast(res.msg);
					if(res.status==200){
						this.$nextTick(()=>{
							setTimeout(()=>{
								this.getimgdetail();
							},500);
						})
					}
				}).catch(err=>{
					
				})
			},
			tovotelist(){
				this.$u.to("/votelist/"+this.id+'/'+this.qi+'/'+this.year);
			},
			useraction(type){
				let uinfo=this.$u.getitem('$uinfo')||{};
				if(!uinfo.token){
					this.$parent.showlogin();
					return;
				}
				if(this.imgdetail[type]){
					return;
				}
				let formdata={
					id:this.imgdetail.id,
					ztid:this.imgdetail.zt_id,
					token:uinfo.token,
					type:type
				}
				this.$u.http("/api/getimg/useraction",{body:formdata}).then(res=>{
					this.$u.toast(res.msg);
					if(res.status==200){
						this.imgdetail[type]=1;
						this.imgdetail[type+'num']+=1;
					}
				}).catch(err=>{
					
				})
			},
			selimg(item){
				this.$router.replace("/imgdetail/"+item.id);
			},
			getimgdetail(){
				let uinfo=this.$u.getitem('$uinfo')||{};
				let formdata={
					id:this.id,
					qi:this.qi,
					pid:this.pid,
					year:this.year
				}
				if(uinfo.token){
					formdata.token=uinfo.token;
				}
				this.$toast.loading({
				  message: '加载中...',
				  forbidClick: true,
				});
				this.$u.http("/api/getimg/getimgdetail",{body:formdata}).then(res=>{
					this.$toast.clear();
					let imgdetail = res.data||{};
					this.imgdetail=imgdetail;
					this.slider=res.slider||[];
					this.getComment();
					this.qi=imgdetail.qi;
					this.year=imgdetail.year;
					this.recommendList=res.recommendList.data;
				}).catch(err=>{
					this.$toast.clear();
				})
			},
			getComment(){
				let formdata={
					article_id:this.imgdetail.id,
				};
				let uinfo=this.$u.getitem('$uinfo')||{};
				if(uinfo.token){
					formdata.token=uinfo.token;
				}
				this.$u.http("/api/getimg/getComment",{body:formdata}).then(res=>{
					let comments = res.data.data||[];
					let hotcomments = res.hotcomments.data||[];
					this.hotcomments=hotcomments;
					this.comments=comments;
					this.uid=res.uid;
				}).catch(err=>{
					this.$toast.clear();
				})
			},
			viewdetailimg(){
				this.viewimg([this.imgdetail.img]);
			},
			afterRead(file) {
			  // 此时可以自行将文件上传至服务器
			  console.log(file);
			},
			completereply(){
				this.getimgdetail();
			},
			addcomment(){
				let uinfo=this.$u.getitem('$uinfo')||{};
				if(!uinfo.token){
					this.$parent.showlogin();
					return;
				}
				this.$refs.addcomment.open({
					article_id:this.imgdetail.id,
					parent_id:0,
				});
				//this.isaddcomment=true;
			},
			viewimg(list){
				ImagePreview(list);
			},
			selqishu(item){
				this.year=item.year;
				this.qi=item.qi;
				this.getimgdetail();
				this.$u.totop();
			},
			showHistory(){
				this.$u.to("/draws/1");
			},
		}
	}
</script>

<style lang="scss">
	.vote_pop{
		padding: 20px;
		background-color: #fff;
		border-radius: 12px;
		.vote_title{
			text-align: center;
			padding-bottom: 12px;
			font-weight: bold;
			font-size: 0.7rem;
		}
		.vote_item{
			text-align: center;
			font-size: 0.7rem;
			border: .01rem solid var(--vi, #3e73fe);
			 background: var(--unnamed, linear-gradient(180deg, hsla(0, 0%, 100%, .2), rgba(62, 115, 254, .2)), #fff);
			 margin-bottom: 8px;
			 border-radius: 8px;
			 padding: 8px 0;
		}
		.active_item{
			background: linear-gradient(180deg, #3e73fe, #2964ff);
			border-color: #2964ff;
			color: #fff;
		}
	}
	.add_commen_page{
		.add_comment_content{
			padding: 10px;
			padding-top: 0;
			.title{
				font-size: 0.6rem;
				padding: 0.6rem 0;
				border-bottom: 1px solid #ddd;
			}
			.comment_con_wrap{
				padding: 10px 0;
				border-bottom: 1px solid #ddd;
			}
			.comment_uploader{
				padding-top: 10px;
			}
		}
	}
	.reply-info-box{
		padding: 10px 0 0 10px;
		.reply-info{
			display: flex;
			margin-bottom: 12px;
			.reply_content{
				flex: 1;
				width: 80%;
				padding-left: 5px;
				.reply_user_info{
					display: flex;
					align-items: center;
					color: #999;
					font-style: normal;
					font-weight: 700;
					font-size: .6rem;
				}
				.reply_content{
					font-size: 0.6rem;
					padding-top: 0.5rem;
					padding-left: 0;
					color: #333;
					line-height: 1.4;
					.clamp-body{
						position: relative;
						word-break: break-all;
					}
				}
			}
		}
	}
	.comment_list{
		
	}
	.comment_wrap{
		background-color: #fff;
		border-radius: 8px;
		padding: 0.8rem 0.5rem;
		.title-bk{
			border-bottom: .02rem solid #e8e8e8;
			margin-left: -.2rem;
			margin-right: -.2rem;
			margin-top: .2rem;
		}
		.details-title_wrap{
			padding: 0.5rem;
			padding-top: 0;
			display: inline-block;
			.details-title{
				position: relative;
				font-size: 0.7rem;
				&::after{
					content: "";
					position: absolute;
					height: 3Px;
					width: 100%;
					left: 0;
					bottom: -5Px;
					background: #3e73fe;
				}
			}
		}
		
	}
	.bot_wrap{
		position: fixed;
		bottom: 0;
		width: 100%;
		.comment_form{
			background-color: #fff;
			padding: 0.3rem;
			.ipt_wrap{
				background: #f2f4f7;
				color: #666;
				padding: 0.4rem 0.5rem;
				border-radius: 2rem;
				font-size: 0.6rem;
			}
		}
		.comment_num{
			display: flex;
			flex-direction: column;
			align-items: center;
		}
		.btn_list{
			display: flex;
			align-items: center;
			padding: 0.3rem;
			background-color: #fff;
			border-top: 1px solid #eee;
			color: #999999;
		}
		.btn_item{
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			font-size: 0.5rem;
			flex: 1;
			div{
				color: #333;
				margin-top: 3px;
			}
		}
	}
.img_detail{
	padding-bottom: 200px;
	font-family: PingFang SC;
	    -webkit-font-smoothing: antialiased;
	.site_wrap{
		background-color: #fff;
		border-radius: 8px;
		padding: 0.8rem 0.5rem;
		.site_title{
			text-align: center;
		}
	}
	.img_recomand{
		background-color: #fff;
		border-radius: 8px;
		padding: 0.8rem 0;
		.img_retitle{
			text-align: center;
			padding-bottom: 0.8rem;
		}
		.img_list{
			padding: 0 10px;
			white-space: nowrap;
			overflow-x: auto;
			.img_item{
				display: inline-block;
				text-align: center;
				width: 23%;
				margin-right: 10px;
				img{
					width: 100%;
				}
				.img_name{
					margin-top: 6px;
					font-size: 0.5rem;
					width: 100%;
					overflow: hidden;
					white-space: nowrap;
				}
			}
		}
	}
	.img_num_wrap{
		background-color: #fff;
		border-radius: 8px;
		text-align: center;
		padding: 0.8rem 0;
		.num{
			color: #000;
		}
		.label{
			color: #999;
			margin-top: 4px;
		}
	}
	.pd12{
		padding: 12px;
	}
	.vote_wrap{
		.vote_btn_wrap{
			display: flex;
			align-items: center;
			justify-content: space-around;
			padding: 10px 0;
			.vote_btn{
				width: 45%;
			}
		}
	}
	.open-hide{
		text-align: center;
		padding: 0.5rem 0;
	}
	.main_img_wrap{
		min-height: 200px;
		img{
			width: 100%;
		}
	}
}
.top_rbtn{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #fff;
	font-size: 0.5rem;
}
</style>