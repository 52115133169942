<template>
	<div class="mainpage">
		<van-nav-bar
		  title="我的关注"
		  left-text=""
		  left-arrow
		  fixed
		  placeholder 
		  @click-left="$u.back"
		/>
		<div class="about_list">
			
		</div>
	</div>
</template>

<script>
	import Vue from 'vue';
	export default{
		data(){
			return{
				apiurl:"/api/getimg/getmyaction",
				query:{
					type:"like"
				},
				islogin:1,
			}
		},
		mounted() {
			
		},
		methods:{
			todetail(item){
				this.$u.to("/imgdetail/"+item.zt_id+"/"+item.pid);
			}
		}
	}
</script>

<style lang="scss">

</style>