<template>
	<div class="mainpage" style="height: auto;">
		<van-nav-bar
		  title="六合图库"
		  left-text=""
		  left-arrow
		  fixed
		  placeholder 
		  @click-left="$u.back"
		/>
		<div style="position: sticky;top: 2rem;z-index: 11;">
			<van-search
			  v-model="kw"
			  show-action
			  label=""
			  left-icon=""
			  shape="round"
			    background="#dc0002"
			  placeholder="搜索"
			  @search="onSearch"
			>
				<template #label>
					<van-popover
					  v-model="showPopover"
					  trigger="click"
					  placement="bottom-start"
					  :actions="actions"
					  @select="onSelect"
					>
					  <template #reference>
					    <span>{{color==1?'彩色':'黑白'}}<van-icon name="arrow-down" /></span>
					  </template>
					</van-popover>
			    </template>
			  <template #action>
			    <div @click="onSearch" class="font35" style="color: #fff;font-weight: bold;">搜索</div>
			  </template>
			</van-search>
		</div>
		<div v-if="isloading" style="height: 100vh;padding: 10px 0;">
			<van-skeleton title :row="3" v-for="(item,index) in 5" style="margin-bottom: 10px;"/>
		</div>
		<div class="imglist_list" v-if="!isloading">
			<van-index-bar :sticky="false"  :index-list="keygroup">
				<div v-for="(value,key,index) in list" :key="index">
				  <van-index-anchor :index="key">{{key}}</van-index-anchor>
				  <van-cell  :title="item1.name" v-for="(item1,index1) in value" @click="todetail(item1)">
					<div class="img_num">{{getqi(item1)}}</div>
				  </van-cell>
				</div>
			</van-index-bar>
		</div>
	</div>
</template>

<script>
	import Vue from 'vue';
	import { Search } from 'vant';
	Vue.use(Search);
	import { Popover } from 'vant';
	Vue.use(Popover);
	import { Cell, CellGroup } from 'vant';
	Vue.use(Cell);
	Vue.use(CellGroup);
	import { IndexBar, IndexAnchor } from 'vant';
	
	Vue.use(IndexBar);
	Vue.use(IndexAnchor);
	export default{
		data(){
			return{
				showPopover: false,
				actions: [{ text: '彩色' }, { text: '黑白' }],
				apiurl:'/index/tuku/getimglist',
				kw:"",
				color:1,
				list:[],
				keygroup:[],
				isloading:false,
			}
		},
		mounted() {
			this.getdatalist();
		},
		methods:{
			getqi(item){
				if(item.qi){
					console.log(item.qi);
					let qi = item.qi+'';
					return qi.replace(item.year+'','');
				}else{
					return item.qi||item.year;
				}
			},
			onSelect(e){
				if(e.text=="彩色"){
					this.color=1;
				}
				if(e.text=="黑白"){
					this.color=2;
				}
				this.getdatalist();
			},
			todetail(item){
				this.$u.to("/imgdetail/"+item.id);
			},
			onSearch(){
				this.keyword=this.kw;
				this.getdatalist();
			},
			groupdata(arr, key){
				return arr.reduce((grouped, item) => {
					const firstLetter = item[key].toUpperCase();
					if (!grouped[firstLetter]) grouped[firstLetter] = [];
					grouped[firstLetter].push(item);
					return grouped;
				}, {});
			},
			getdatalist(){
				return new Promise((reponse,reject)=>{
					let formdata={
						color:this.color,
					}
					if(this.keyword){
						formdata.keyword=this.kw;
					}
					this.$u.http(this.apiurl,{body:formdata}).then(res=>{
						let datalist = res.data;
						this.list=this.groupdata(datalist,'code');
						this.keygroup=Object.keys(this.list);
						console.log(this.list);
						reponse(datalist);
					}).catch(err=>{
						console.log(err);
						reject();
					})
				})
				
			}
		}
	}
</script>

<style lang="scss">
	.imglist_list{
		background-color: inherit;
		font-family: PingFangSC-Regular, Microsoft YaHei, Helvetica;
		.img_num{
			display: inline-block;
			color: #3e73fe;
			padding: 0 .5rem;
			background: #f2f2f2;
			border-radius: .14rem;
			font-size: .54rem;
		}
		.data_list{
			display: flex;
			gap: 0.4rem;
			padding: 10px;
			.data_list_left{
				width: calc(50% - 0.2rem);
			}
			.data_list_right{
				width: calc(50% - 0.2rem);
			}
			.data_item{
				text-align: center;
				width: 100%;
				background-color: #fff;
				border-radius: 5px;
				margin-bottom: 10px;
				.data_name{
					padding: 5px;
				}
			}
		}
	}
</style>