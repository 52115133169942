import router from '@/router'
import { Toast } from 'vant';

let baseurl=location.origin;
if(process.env.NODE_ENV=="development"){
	baseurl="http://809tk.888yuanma.top";//http://124.222.205.17:8084
}
let utils={
	domain:baseurl,
	async http(api,options ){
		let url = baseurl+api;
		if(api.indexOf('http')>-1){
			url = api;
		}
	  const defaultOptions = {
		method: 'POST', 
		headers: {
		  //'Content-Type': 'application/json', 
		},
		timeout: 30000,
	  };
	  if(options&&options.body){
		  const formData = new FormData();
		  let data=options.body;
		  for (const key in data) {
		    if (Object.prototype.hasOwnProperty.call(data, key)) {
				if(key=='imglist'){
					if(data[key].length!=0){
						data[key].forEach((file, index) => {
							formData.append(`images[]`, file); // 添加图片文件
						});
					}else{
						formData.append(`images[]`, '');
					}
				}else{
					formData.append(key, data[key]);
				}
		    }
		  }
		  options.body = formData;
	  }
	  const finalOptions = { ...defaultOptions, ...options };
	  const { timeout, ...fetchOptions } = finalOptions;
	
	  const timeoutPromise = new Promise((_, reject) =>
		setTimeout(() => reject(new Error('Request timeout')), timeout)
	  );
	  try {
		const response = await Promise.race([
		  fetch(url, fetchOptions),
		  timeoutPromise,
		]);
		if (!response.ok) {
		  throw new Error(`HTTP error! Status: ${response.status}`);
		}
		const data = await response.json();
		return data;
	
	  } catch (error) {
		console.error('Fetch request failed:', error);
		throw error;
	  }
	},
	login(loginform){
		let self=this;
		let formdata=loginform;
		return new Promise((response,reject)=>{
			if(formdata.username==''){
				 self.toast('请输入用户名');
				 reject();
				 return;
			}
			if(formdata.password==''){
				 self.toast('请输入密码');
				 reject();
				 return;
			}
			self.http("/api/index/login",{body:formdata}).then(res=>{
				self.toast(res.msg);
				if(res.status==200){
					self.setitem('$uinfo',res.data);
					response(res);
				}else{
					reject();
				}
			}).catch(err=>{
				reject();
			})
		})
	},
	getconfig(){
		let self=this;
		return new Promise((response,reject)=>{
			const reqUrl = '/Index/indexData';
			self.http(reqUrl).then(data => {
				let configdata = data;
				response(configdata);
			}).catch(err=>{
				reject();
			});
		})
	},
	getuinfo(){
		let self=this;
		return self.getitem('$uinfo')||{};
	},
	setitem(name,value){
		try {
		  const serializedValue = JSON.stringify(value);
		  localStorage.setItem(name, serializedValue);
		} catch (error) {
		  console.error("Failed to save to localStorage", error);
		}
	},
	getitem(name){
		try {
		  const serializedValue = localStorage.getItem(name);
		  return serializedValue ? JSON.parse(serializedValue) : null;
		} catch (error) {
		  console.error("Failed to read from localStorage", error);
		  return '';
		}
	},
	removeitem(key) {
	    localStorage.removeItem(key);
	},
	back(){
		router.back();
	},
	to(path){
		router.push(path);
	},
	toast(str){
		Toast(str)
	},
	totop() {
	  window.scrollTo({
	    top: 0,
	    left: 0,
	    behavior: 'smooth' // 可选，平滑滚动效果
	  });
	},
	getlogo(){
		return "https://jmz.xinchangcheng.net:4949/tk118files/system/logo_large.png";
	},
	parseTime(time, cFormat) {
	  if (arguments.length === 0 || !time) {
	    return null
	  }
	  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
	  let date
	  if (typeof time === 'object') {
	    date = time
	  } else {
	    if ((typeof time === 'string')) {
	      if ((/^[0-9]+$/.test(time))) {
	        // support "1548221490638"
	        time = parseInt(time)
	      } else {
	        // support safari
	        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
	        time = time.replace(new RegExp(/-/gm), '/')
	      }
	    }
	
	    if ((typeof time === 'number') && (time.toString().length === 10)) {
	      time = time * 1000
	    }
	    date = new Date(time)
	  }
	  const formatObj = {
	    y: date.getFullYear(),
	    m: date.getMonth() + 1,
	    d: date.getDate(),
	    h: date.getHours(),
	    i: date.getMinutes(),
	    s: date.getSeconds(),
	    a: date.getDay()
	  }
	  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
	    const value = formatObj[key]
	    // Note: getDay() returns 0 on Sunday
	    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value ] }
	    return value.toString().padStart(2, '0')
	  })
	  return time_str
	},
	calctime(timestamp){
		if(!timestamp){
			return ''
		}
		var self=this;
		let timestr=+timestamp;
		if(timestamp.toString().length==10){
			timestr = timestamp*1000;
		}
		var distance=+new Date() - timestr;
		var day=distance/1000/60/60/24;
		if (day>7) {
			return self.parseTime(timestamp);
		} else{
			var hour=distance/ 1000 / 60 / 60;
			if(hour>24){
				return Math.round(day)+"天前";
			}else{
				if(hour>1){
					return Math.round(hour)+"小时前";
				}else{
					var minite=Math.round(hour*60);
					if(minite){
						return minite+"分钟前"
					}else{
						return "刚刚";
					}
				}
			}
		}
	},
	copy(text){
		let self=this;
		if (!navigator.clipboard) {
			// 如果 Clipboard API 不可用，则使用文本区域辅助复制
			const textarea = document.createElement('textarea');
			textarea.value = text;
			textarea.style.position = 'fixed'; // 避免影响页面布局
			textarea.style.top = '-9999px';   // 隐藏在页面外
			document.body.appendChild(textarea);
			textarea.select();               // 选择内容
			try {
				document.execCommand('copy');
				self.toast('已复制');
			} catch (err) {
				console.error('复制失败：', err);
			}
			document.body.removeChild(textarea);
		} else {
			// 使用 Clipboard API 进行复制
			navigator.clipboard.writeText(text).then(() => {
				self.toast('已复制');
			}).catch(err => {
				console.error('复制失败：', err);
			});
		}
	}
}

export default utils;