<template>
	<div class="mainpage">
		<van-nav-bar
		  title="消息"
		  left-text=""
		  right-text="客服"
		  left-arrow
		  fixed
		  placeholder 
		  @click-left="$u.back"
		  @click-right="$u.to('/service')"
		/>
		<div class="sitemsg_page">
			<van-cell-group>
			  <van-cell  :value="msgtime" label="官方消息/活动等" @click="topage('/official')">
				<template #title>
				  <span class="msg-title">官方消息</span>
				</template>
			    <template #icon>
					<div class="site_icon">
						<van-image
							width="100%"
							height="100%"
							fit="cover"
							:src="configdata.logo" v-if="configdata.logo"
						/>
					</div>
			    </template>
			  </van-cell>
			  <van-cell label="系统推送/审核通知等"  @click="topage('/system')">
				<template #title>
				  <span class="msg-title">系统通知</span>
				</template>
			    <template #icon>
			      <img src="@/assets/imgs/msgicon1.png" class="site_icon"/>
			    </template>
			  </van-cell>
			  <van-cell label="评论互动消息登" v-if="false">
				<template #title>
				  <span class="msg-title">互动消息</span>
				</template>
			    <template #icon>
			      <img src="@/assets/imgs/msgicon2.png" class="site_icon"/>
			    </template>
			  </van-cell>
			</van-cell-group>
		</div>
	</div>
</template>

<script>
	import Vue from 'vue';
	import {  Cell, CellGroup } from 'vant';
	
	Vue.use(Cell);
	Vue.use(CellGroup);
	let kefuicon = require("@/assets/imgs/kficon.png");
	export default{
		data(){
			return{
				kefuicon:kefuicon,
				msgtime:"",
				configdata:this.configdefault
			}
		},
		mounted() {
			this.getdata();
			this.$u.getconfig().then(res=>{
				this.configdata=res;
			})
		},
		methods:{
			getdata(){
				let formdata={
					type:1,
					page:1,
					limit:1,
				}
				this.$u.http("/api/index/getnotice",{body:formdata}).then(res=>{
					if(res.data&&res.data.data&&res.data.data.length>0){
						this.msgtime=this.$u.parseTime(res.data.data[0].createtime);
					}
				})
			},
			topage(path){
				this.$u.to(path);
			}
		}
	}
</script>

<style lang="scss">
	.sitemsg_page{
		background-color: #fff;
		.msg-title{
			font-weight: bold;
		}
		.site_icon{
			width: 34px;
			height: 34px;
			margin-right: 10px;
			img{
				width: 100%;
				height: 100%;
				border-radius: 50%;
			}
		}
	}
</style>