<template>
	<div>
		<div class="comment_item" v-for="(item,index) in commentlist" :key="index">
			<div class="comment_item_left">
				<img :src="userlogo" width="40" height="40" style="border-radius: 50%;"/>
				<div class="font15">{{index+1}}楼</div>
			</div>
			<div class="comment_item_right">
				<div class="user_name">
					<span>{{item.username}}</span>
					<span class="user_level_tag">LV.{{item.level}}</span>
				</div>
				<div class="comment_content">
					<span class="clamp-body" style="overflow: hidden; white-space: pre-wrap;"
						v-html="item.content"
						>
					</span>
				</div>
				<div class="images-box" v-if="item.images">
					<div class="img_item"  v-for="(item1,index1) in item.images.split(',')" 
						@click="viewimg(item.images,index1)">
						<van-image
							width="100%"
							height="100%"
							fit="cover"
							:src="$u.domain+item1"
						/>
					</div>
				</div>
				<div class="desc_info">
					<span class="comment_time">{{$u.calctime(item.created_at)}}</span>
					<div class="reply_btn" @click="reply(item,index)">回复</div>
					<span class="like_num" @click="dolike(item,index)">
						<van-icon name="good-job-o" size="18px"/>
						<span style="margin-left: 0.1rem;">{{item.like_count}}</span>
					</span>
					<div class="del_btn" @click="delcomment(item,index,1)" v-if="userid==item.uid">删除</div>
				</div>
				<div class="reply-info-box" v-if="item.isshowreply">
					<div class="reply-info" v-for="(item2,index2) in item.replylist" v-if="item.replylist&&item.replylist.length!=0">
						<van-image
							round
							width="20"
							height="20"
							:src="userlogo"
						/>
						<div class="reply_content">
							<div class="reply_user_info">
								<span class="reply_user_name">{{item2.username}}</span>
								<span style="margin: 0 5px;"><van-icon name="play" /></span>
								<span class="reply_user_name">{{item2.to_username}}</span>
							</div>
							<div class="reply_content">
								<span class="clamp-body" style="overflow: hidden; white-space: pre-wrap;"
								v-html="item2.content"></span>
							</div>
							<div class="images-box" v-if="item2.images">
								<div class="img_item" v-for="(item3,index3) in item2.images.split(',')" 
									@click="viewimg(item2.images,index3)">
									<van-image
										width="100%"
										height="100%"
										fit="cover"
										:src="$u.domain+item3"
									/>
								</div>
							</div>
							<div class="desc_info">
								<span class="comment_time">{{$u.calctime(item2.created_at)}}</span>
								<div class="reply_btn" @click="childreply(item,index,item2)">回复</div>
								<div class="del_btn" @click="delcomment(item2,index2,2)" v-if="userid==item2.uid">删除</div>
							</div>
						</div>
					</div>
				</div>
				<div class="operate" v-if="item.reply_count!=0">
					<b class="minus"></b>
					<div @click="getreply(item,index)">
						<span class="op_txt">展开<span v-if="!item.isshowreply">{{item.reply_count}}条回复</span></span>
						<van-icon name="arrow-down" />
					</div>
					<div @click="hidereply(item,index)" v-if="item.isshowreply">
						<span class="op_txt">收起</span>
						<van-icon name="arrow-up" />
					</div>
				</div>
			</div>
		</div>
		<addcomment ref="addcomment" @completereply="completereply"/>
	</div>
</template>

<script>
	import { ImagePreview } from 'vant';
	import addcomment from '@/components/addcomment.vue';
	import { Dialog } from 'vant';
	export default{
		props:['datalist','uid'],
		components:{addcomment},
		data(){
			return {
				commentlist:this.datalist,
				replylist:[],
				isshowreply:false,
				page:1,
				limit:20,
				userlogo:this.configdefault.logo,
				userid:"",
				comindex:0,
			}
		},
		watch: {
			datalist(newValue) {
			  this.commentlist = newValue;
			},
			uid(newValue) {
			  this.userid = newValue;
			},
		},
		mounted(){
			this.userid = this.uid;
			this.$u.getconfig().then(res=>{
				this.userlogo=res.logo;
			})
		},
		methods:{
			dolike(item,index){
				let uinfo=this.$u.getitem('$uinfo')||{};
				if(!uinfo.token){
					this.$emit('showlogin');
					return;
				}
				this.$toast.loading({
				  message: '加载中...',
				  forbidClick: true,
				});
				let formdata={
					id:item.id,
					token:uinfo.token,
					type:'commentlike',
				}
				this.$u.http("/api/getimg/dolikecomment",{body:formdata}).then(res=>{
					this.$toast.clear();
					this.$u.toast(res.msg);
					if(res.status==200){
						this.commentlist[index].like_count+=1;
						this.$set(this.commentlist,index,this.commentlist[index]);
					}
				}).catch(err=>{
					this.$toast.clear();
				})
			},
			getreply(item,index){
				if(!item.isshowreply){
					this.page=1;
					item.replylist=[];
				}
				this.$toast.loading({
				  message: '加载中...',
				  forbidClick: true,
				});
				let formdata={
					page:this.page,
					limit:this.limit,
					id:item.id
				}
				let uinfo=this.$u.getitem('$uinfo')||{};
				if(uinfo.token){
					formdata.token=uinfo.token;
				}
				this.$u.http("/api/getimg/getreply",{body:formdata}).then(res=>{
					this.$toast.clear();
					let replylist = res.data.data||[];
					this.userid=res.uid;
					let curcomitem=this.commentlist[index];
					curcomitem.replylist=curcomitem.replylist?curcomitem.replylist:[];
					curcomitem.replylist=curcomitem.replylist.concat(replylist);
					if(replylist.length!=0){
						this.page+=1;
					}
					this.$nextTick(()=>{
						curcomitem.isshowreply=true;
						this.$set(this.commentlist,index,curcomitem);
					})
				}).catch(err=>{
					this.$toast.clear();
				})
			},
			hidereply(item,index){
				item.isshowreply=false;
				this.$set(this.commentlist,index,item);
			},
			completereply(item){
				this.page=1;
				this.commentlist[this.index].reply_count+=1;
				this.commentlist[this.index].replylist=[];
				this.$set(this.commentlist,this.index,this.commentlist[this.index]);
				this.getreply(item,this.comindex);
			},
			reply(item,index){
				this.comindex=index;
				this.comreply({item:item,index:index});
			},
			childreply(item,index,item2){
				this.comindex=index;
				console.log(index);
				let dataitem={
					article_id:item.article_id,
					id:item.id,
					uid:item2.uid,
					username:item.username
				};
				this.comreply({item:dataitem,index:index});
			},
			comreply(data){
				let uinfo=this.$u.getitem('$uinfo')||{};
				if(!uinfo.token){
					this.$emit('showlogin');
					return;
				}
				let {item,index}=data;
				console.log(item);
				this.index=index;
				this.$refs.addcomment.open({
					article_id:item.article_id,
					parent_id:item.id,
					reply_to_user_id:item.uid,
					parent_name:item.username
				});
			},
			viewimg(list,index){
				let imgs=list.split(',');
				let domain = this.$u.domain;
				imgs = imgs.map(item=>{
					return domain+item;
				})
				ImagePreview(imgs,index);
			},
			delcomment(item,index,type){
				let self=this;
				let uinfo=this.$u.getitem('$uinfo')||{};
				if(!uinfo.token){
					this.$parent.showlogin();
					return;
				}
				Dialog.confirm({
				  title: '删除提示',
				  message: '确定删除此评论吗？',
				}).then(() => {
					self.dodel(item,index,type);
				}).catch(() => {
					// on cancel
				});
			},
			dodel(item,index,type){
				let uinfo=this.$u.getitem('$uinfo')||{};
				if(!uinfo.token){
					this.$parent.showlogin();
					return;
				}
				let formdata={
					token:uinfo.token,
					id:item.id,
				}
				this.$u.http("/api/getimg/delcomments",{body:formdata}).then(res=>{
					this.$u.toast(res.msg);
					if(res.status==200){
						if(type==1){
							this.commentlist.splice(index,1);
						}else{
							item.replylist.splice(index,1);
							this.$set(this.commentlist,index,item);
						}
					}
				}).catch(err=>{
					
				});
			},
		}
	}
</script>

<style lang="scss">
	.comment_item{
		display: flex;
		padding: 0.5rem 0;
		.comment_item_left{
			text-align: center;
			color: #888;
		}
		.comment_item_right{
			flex: 1;
			width: 80%;
			padding-left: 0.5rem;
			.user_name{
				color: #333;
				font-size: 0.7rem;
				font-weight: bold;
				display: flex;
				align-items: center;
				.user_level_tag{
					font-weight: normal;
					border-radius: .18rem;
					background: #ff5c00;
					color: #fff;
					font-family: Noto Sans SC;
					font-size: 0.5rem;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					padding: 0 .2rem;
					display: inline-block;
					margin-left: 3px;
				}
			}
			.comment_content{
				font-size: 0.58rem;
				padding-top: 0.5rem;
				color: #333;
				line-height: 1.5;
				.clamp-body{
					position: relative;
					word-break: break-all;
					font-family: PingFang SC;
				}
			}
			.images-box{
				display: flex;
				flex-wrap: wrap;
				gap: 0.3rem;
				margin-top: 0.5rem;
				.img_item{
					width: calc(calc(100% - 0.6rem)/3);
					height: 3.9rem;
				}
			}
			.desc_info{
				display: flex;
				align-items: center;
				margin-top: 0.25rem;
				.comment_time{
					font-size: .5rem;
					color: #888;
				}
				.reply_btn{
					margin: 0 0.4rem;
					padding: .12rem .6rem .12rem .6rem;
					background-color: #ededed;
					border-radius: .6rem;
					display: inline-block;
					text-align: center;
					font-size: .48rem;
					color: #555;
				}
				.del_btn{
					color: #999;
					margin-left: 0.5rem;
					font-size: .48rem;
				}
				.like_num{
					color: #666;
					font-size: .58rem;
					margin-left: 0.1rem;
				}
			}
			.operate{
				font-size: .6rem;
				margin-top: .5rem;
				font-weight: 600;
				color: #8c8c8c;
				display: flex;
				align-items: center;
				.minus{
					width: 1.2rem;
					height: .04rem;
					background: #8c8c8c;
					display: inline-block;
				}
				.op_txt{
					margin-left:0.5rem;
					margin-right: 0.2rem;
				}
			}
		}
	}
</style>