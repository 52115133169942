<template>
	<div class="mainpage login_page">
		<van-nav-bar
		  title=""
		  left-text=""
		  left-arrow
		  fixed
		  placeholder 
		  @click-left="$u.back"
		/>
		<div class="login_content">
			<div class="welcome_wrap">
				<div class="login_title">
					<div class="title1">你好！</div>
					<div class="title2">欢迎来到{{configdata.title||''}}！</div>
				</div>
				<div class="wel_logo">
					<van-image
						width="100%"
						height="100%"
						fit="cover"
						:src="configdata.logo" v-if="configdata.logo"
					/>
				</div>
			</div>
			<div class="form_wrap">
				<van-tabs v-model="active" color="#3e73fe" title-inactive-color="#3e73fe" title-active-color="#3e73fe" line-width="17px">
				  <van-tab title="密码登录"></van-tab>
				  <!-- <van-tab title="验证码登录"></van-tab> -->
				</van-tabs>
				<div class="form_ipt_wrap">
					<div class="form_ipt">
						<input placeholder="输入您的手机号码" v-model="loginform.username" maxlength="11" type="number" class="font25"/>
					</div>
					<div class="form_ipt">
						<input placeholder="输入您的密码" v-model="loginform.password" class="font25" type="password" maxlength="22"/>
						<span class="mcolor font25" @click="toforget">忘记密码?</span>
					</div>
				</div>
				<div class="form_footer">
					<van-button type="info" block class="log_btn" @click="dologin">登录</van-button>
					<div class="re_txt font25" @click="toregister">注册</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				active:0,
				loginform:{
					username:"",
					password:""
				},
				configdata:this.configdefault
			}
		},
		mounted() {
			this.$u.getconfig().then(res=>{
				this.configdata=res;
			})
		},
		methods:{
			dologin(){
				this.$u.login(this.loginform).then(res=>{
					this.$router.replace('/person');
				}).catch(err=>{
					
				})
			},
			toregister(){
				this.$u.to("/register");
			},
			toforget(){
				this.$u.to("/forget");
			}
		}
	}
</script>

<style lang="scss">
	.login_page{
		height: 100vh;
		position: relative;
		padding: 0 1rem 0;
		background: url(../../assets/imgs/loginbg.png) no-repeat;
		background-size: 100% auto;
		background-position: 0 1.92rem;
	}
	.login_content{
		.form_wrap{
			margin-top: 1rem;
			padding: 0 0.6rem 0.6rem 0.6rem;
			background-color: #fff;
			border-radius: 16px;
			.form_footer{
				margin-top: 2rem;
				.log_btn{
					 background: linear-gradient(180deg, $mcolor1, $mcolor2);
					 border-color: $mcolor1;
					 font-size: 0.8rem;
					 border-radius: 10px;
					 height: 50px;
				}
				.re_txt{
					text-align: center;
					padding: 0.5rem 0;
				}
			}
			.form_ipt_wrap{
				margin-top: 20px;
				.form_ipt{
					display: flex;
					padding:0.65rem 0.5rem;
					border-radius: 10px;
					background-color: #f2f4f7;
					margin-bottom: 30px;
					input{
						border: none;
						outline: none;
						background-color: transparent;
						flex: 1;
						width: 70%;
						color: #555;
						&::placeholder{
							color: #ccc;
						}
					}
				}
			}
		}
		.welcome_wrap{
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 10px 10px 0 0;
			.login_title{
				.title1{
					font-size: 1.1rem;
					font-weight: bold;
					color: #333;
				}
				.title2{
					font-size: 0.85rem;
					font-weight: bold;
					color: #333;
					margin-top: 0.5rem;
				}
			}
			.wel_logo{
				width: 110px;
				height: 110px;
				img{
					width: 100%;
					height: 100%;
				}
			}
		}
	}
</style>