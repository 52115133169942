<template>
	<div class="mainpage" style="height: auto;background-color: #fff;">
		<van-nav-bar
		  title="投票详情"
		  left-text=""
		  left-arrow
		  fixed
		  placeholder 
		  @click-left="$u.back"
		>
		</van-nav-bar>
		<periodsFilter :id="id" 
		  :qi="qi"
		  :year="year"
		  @selqishu="selqishu" v-if="id"/>
		  <drawitem :qi="qi"/>
		<div class="img_detail">
			<div class="main_img_wrap">
				<voteItem :imgdetail="imgdetail" v-if="imgdetail.id"/>
			</div>
		</div>
	</div>
</template>

<script>
	import Vue from 'vue';
	import periodsFilter from '../../components/periodsFilter.vue';
	import drawitem from '@/components/drawitem.vue';
	import voteItem from '../../components/vote-item.vue';
	import { DropdownMenu, DropdownItem } from 'vant';
	Vue.use(DropdownMenu);
	Vue.use(DropdownItem);
	export default{
		components:{periodsFilter,voteItem,drawitem},
		data(){
			return{
				id:"",
				qi:"",
				year:"",
				imgdetail:{}
			}
		},
		mounted() {
			if(this.$route.params.id){
				this.id=this.$route.params.id;
				if(this.$route.params.qi){
					this.qi=this.$route.params.qi;
				}
				if(this.$route.params.year){
					this.year=this.$route.params.year;
					this.getimgdetail();
				}
			}
			console.log(this.$route.params.id);
		},
		methods:{
			selqishu(item){
				this.qi=item.qi;
				this.year=item.year;
				this.getimgdetail();
			},
			getimgdetail(){
				let uinfo=this.$u.getitem('$uinfo')||{};
				let formdata={
					id:this.id,
					qi:this.qi,
					pid:this.pid,
					year:this.year
				}
				if(uinfo.token){
					formdata.token=uinfo.token;
				}
				this.$toast.loading({
				  message: '加载中...',
				  forbidClick: true,
				});
				this.$u.http("/api/getimg/getimgdetail",{body:formdata}).then(res=>{
					this.$toast.clear();
					let imgdetail = res.data||{};
					this.$u.setitem('$imgdetail',imgdetail)
					this.imgdetail=imgdetail;
					this.qi=imgdetail.qi;
					this.year=imgdetail.year;
				}).catch(err=>{
					this.$toast.clear();
				})
			},
			showHistory(){
				
			},
			addComment(){
				
			}
		}
	}
</script>

<style lang="scss">
.img_detail{
	.main_img_wrap{
		
	}
}
.top_rbtn{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #fff;
	font-size: 0.5rem;
}
</style>