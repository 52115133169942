<template>
  <div>
    <canvas 
      ref="canvas" 
      :width="canvasWidth" 
      :height="canvasHeight" 
      @click="generateCode"
      style="cursor: pointer; ">
    </canvas>
  </div>
</template>

<script>
export default {
  data() {
    return {
      verificationCode: '', // 保存验证码
      canvasWidth: 100,     // Canvas 宽度
      canvasHeight: 40,     // Canvas 高度
    };
  },
  mounted() {
    this.generateCode(); // 初始化生成验证码
  },
  methods: {
	  checkcode(code){
		return  code==this.verificationCode;
	  },
    // 生成随机验证码
    generateCode() {
      this.verificationCode = this.randomCode(4);
      this.drawCanvas(this.verificationCode);
    },
    // 随机生成 n 位验证码
    randomCode(n) {
      const chars = '0123456789';
      let code = '';
      for (let i = 0; i < n; i++) {
        code += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return code;
    },
    // 在 Canvas 上绘制验证码
    drawCanvas(code) {
      const canvas = this.$refs.canvas;
      const ctx = canvas.getContext('2d');

      // 清空画布
      ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);

      // 绘制背景
      ctx.fillStyle = '#ffffff';
      ctx.fillRect(0, 0, this.canvasWidth, this.canvasHeight);

      // 绘制验证码
      ctx.font = '30px Arial';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillStyle = this.randomColor();
      ctx.fillText(code, this.canvasWidth / 2, this.canvasHeight / 2);

      // 添加干扰线条
      for (let i = 0; i < 2; i++) {
        ctx.strokeStyle = this.randomColor();
        ctx.beginPath();
        ctx.moveTo(
          Math.random() * this.canvasWidth,
          Math.random() * this.canvasHeight
        );
        ctx.lineTo(
          Math.random() * this.canvasWidth,
          Math.random() * this.canvasHeight
        );
        ctx.stroke();
      }

      // 添加干扰点
      for (let i = 0; i < 10; i++) {
        ctx.fillStyle = this.randomColor();
        ctx.beginPath();
        ctx.arc(
          Math.random() * this.canvasWidth,
          Math.random() * this.canvasHeight,
          Math.random() * 2,
          0,
          Math.PI * 2
        );
        ctx.fill();
      }
    },
    // 随机生成颜色
    randomColor() {
      return `rgb(${Math.floor(Math.random() * 256)}, ${Math.floor(
        Math.random() * 256
      )}, ${Math.floor(Math.random() * 256)})`;
    },
  },
};
</script>

<style scoped>
canvas {
  display: block;
}
</style>
