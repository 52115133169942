<template>
	<div class="mainpage">
		<van-nav-bar
		  title="我的收藏"
		  left-text=""
		  left-arrow
		  fixed
		  placeholder 
		  @click-left="$u.back"
		/>
		<div class="col_page">
			<van-pull-refresh v-model="refreshing" @refresh="onRefresh">
				<van-list
				  v-model="loading"
				  :finished="finished"
				  finished-text="没有更多了"
				  @load="loadmore"
				>
				  <div class="data_list">
					  <van-cell value="" is-link class="flex_center" v-for="(item,index) in list" :key="index" @click="todetail(item)">
						  <template #icon>
							  <van-image
							    width="100"
							    height="70"
								fit="cover"
							    :src="item.img"
							  />
						  </template>
					      <div style="padding-left: 10px;">
							  {{item.name}}
						  </div>
					  </van-cell>
				  </div>
				</van-list>
			</van-pull-refresh>
		</div>
	</div>
</template>

<script>
	import Vue from 'vue';
	import datalistmixin from '@/assets/mixin/datalist.js';
	export default{
		mixins: [datalistmixin],
		data(){
			return{
				apiurl:"/api/getimg/getmyaction",
				query:{
					type:"col"
				},
				islogin:1,
			}
		},
		mounted() {
			
		},
		methods:{
			todetail(item){
				this.$u.to("/imgdetail/"+item.zt_id+"/"+item.pid);
			}
		}
	}
</script>

<style lang="scss">
.col_page{
	.data_list{
		
	}
	.data_item{
		
	}
}
</style>