<template>
	<van-popup v-model="isaddcomment" position="right" style="width: 100vw;height: 100vh;" >
		<div class="add_commen_page">
			<van-nav-bar
			  title="评论"
			  left-text=""
			  left-arrow
			  fixed
			  placeholder 
			  @click-left="isaddcomment=false"
			/>
			<div class="add_comment_content">
				<div class="title">{{parent_id==0?'发布':'回复'}}评论</div>
				<div class="comment_con_wrap">
					<van-field
					style="padding: 0;"
					  v-model="content"
					  rows="6"
					  autosize
					  label=""
					  type="textarea"
					  maxlength="1200"
					  :placeholder="parent_name?('回复 '+parent_name):'请输入评论的内容'"
					  show-word-limit
					/>
				</div>
				<div class="comment_uploader">
					<van-uploader  v-model="fileList" :after-read="afterRead" />
				</div>
				
				<div style="margin: 16px;">
				  <van-button round block type="info" native-type="submit" :loading="loading" @click="reportComment">发布</van-button>
				</div>
			</div>
		</div>
	</van-popup>
</template>

<script>
	export default{
		data(){
			return{
				fileList:[],
				content:"",
				loading:false,
				isaddcomment:false,
				article_id:"",
				parent_id:0,
				parent_name:"",
				reply_to_user_id:"",
				params:{}
			}
		},
		watch:{
			isaddcomment(val){
				if(!val){
					this.fileList=[];
					this.content="";
				}
			}
		},
		mounted() {
			
		},
		methods:{
			reportComment(){
				let uinfo=this.$u.getitem('$uinfo')||{};
				if(!uinfo.token){
					this.$parent.showlogin();
					return;
				}
				if(this.content==''){
					return this.$u.toast("请输入评论内容！")
				}
				this.loading=true;
				let imglist=[];
				if(this.fileList.length!=0){
					this.fileList.map(item=>{
						imglist.push(item.file);
					})
				}
				let formdata={
					token:uinfo.token,
					article_id:this.article_id,
					parent_id:this.parent_id,
					reply_to_user_id:this.reply_to_user_id,
					content:this.content,
					imglist:imglist
				}
				this.$u.http("/api/getimg/addComment",{body:formdata}).then(res=>{
					this.loading=false;
					this.$u.toast(res.msg);
					if(res.status==200){
						this.isaddcomment=false;
						this.$emit('completereply',{id:this.parent_id});
					}
				}).catch(err=>{
					this.loading=false;
				});
			},
			open(params){
				this.params=params;
				this.article_id=params.article_id;
				this.parent_id=params.parent_id||0;
				this.parent_name=params.parent_name||'';
				this.reply_to_user_id=params.reply_to_user_id||'';
				this.isaddcomment=true;
			},
			afterRead(e){
				
			}
		}
	}
</script>

<style lang="scss">
	.add_commen_page{
		.add_comment_content{
			padding: 10px;
			padding-top: 0;
			.title{
				font-size: 0.6rem;
				padding: 0.6rem 0;
				border-bottom: 1px solid #ddd;
			}
			.comment_con_wrap{
				padding: 10px 0;
				border-bottom: 1px solid #ddd;
			}
			.comment_uploader{
				padding-top: 10px;
			}
		}
	}
</style>