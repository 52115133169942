<template>
	<div class="mainpage">
		<van-nav-bar
		  title="意见反馈"
		  left-text=""
		  left-arrow
		  fixed
		  placeholder 
		  @click-left="$u.back"
		/>
		<div>
			<van-form @submit="onSubmit" :show-error="false">
				<van-panel title="标题" desc="" status="">
				  <div>
					  <van-field
					    v-model="title"
					    name=""
					    label=""
					    placeholder="请输入您反馈的标题"
					    :rules="[{ required: true, message: '请输入您反馈的标题' }]"
					  />
				  </div>
				</van-panel>
				<van-panel title="内容" desc="" status="">
				  <div>
					  <van-field
					    v-model="content"
					    name=""
					    label=""
						rows="4"
						type="textarea"
					    placeholder="请输入您反馈的内容或者建议..."
					    :rules="[{ required: true, message: '请输入您反馈的内容或者建议...' }]"
					  />
				  </div>
				</van-panel>
				<van-field name="uploader" label="">
				  <template #input>
				    <van-uploader v-model="uploader" max-count="1" :after-read="afterRead" />
				  </template>
				</van-field>
			  <div style="margin: 16px;">
			    <van-button round block type="info" native-type="submit" :loading="loading">提交</van-button>
			  </div>
			</van-form>
		</div>
	</div>
</template>

<script>
	import Vue from 'vue';
	import { NavBar,Panel,Uploader,Form  } from 'vant';
	Vue.use(NavBar).use(Panel).use(Uploader).use(Form);
	export default{
		data(){
			return{
				title: '',
				content: '',
				uploader: [],
				loading :false,
				file:""
			}
		},
		mounted() {
			
		},
		methods:{
			afterRead(file) {
			  this.file=file.file;
			},
			onSubmit(values) {
				let uinfo=this.$u.getitem('$uinfo')||{};
				if(!uinfo.token){
					this.$parent.showlogin();
					return;
				}
				this.loading=true;
				let formdata={
					token:uinfo.token,
					title:this.title,
					content:this.content,
					file:this.file
				}
				this.$u.http("/api/feedback/add",{body:formdata}).then(res=>{
					this.loading=false;
					this.$u.toast(res.msg);
					if(res.status==200){
						this.$u.back();
					}
				}).catch(err=>{
					this.loading=false;
				});
			},
		}
	}
</script>

<style lang="scss">
	
</style>