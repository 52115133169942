import { render, staticRenderFns } from "./vercode.vue?vue&type=template&id=57c3848c&scoped=true"
import script from "./vercode.vue?vue&type=script&lang=js"
export * from "./vercode.vue?vue&type=script&lang=js"
import style0 from "./vercode.vue?vue&type=style&index=0&id=57c3848c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57c3848c",
  null
  
)

export default component.exports