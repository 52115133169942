<template>
	<div class="mainpage">
		<van-nav-bar
		  title="资料大全"
		  left-text=""
		  
		  fixed
		  placeholder 
		  @click-left="$u.back"
		/>
		<van-search
		  v-model="kw"
		  show-action
		  label=""
		  placeholder="搜索相关标题文章"
		  @search="onSearch"
		>
		  <template #action>
		    <div @click="onSearch">搜索</div>
		  </template>
		</van-search>
		<div class="database_list">
			<van-pull-refresh v-model="refreshing" @refresh="onRefresh">
				<van-list
				  v-model="loading"
				  :finished="finished"
				  finished-text="没有更多了"
				  @load="loadmore"
				>
				  <div class="data_list">
					  <div class="data_item font25" v-for="(item,index) in list" :key="index" @click="todetail(item)">
						  {{item.subtitle}}
					  </div>
				  </div>
				</van-list>
			</van-pull-refresh>
		</div>
		<tabbar/>
	</div>
</template>

<script>
	import Vue from 'vue';
	import { Search } from 'vant';
	Vue.use(Search);
	import datalistmixin from '@/assets/mixin/datalist.js';
	export default{
		mixins: [datalistmixin],
		data(){
			return{
				apiurl:'/api/index/getziliaoku',
				query:{
					dh_id:1,
					limit:80,
				}
			}
		},
		mounted() {
			
		},
		methods:{
			todetail(item){
				this.$u.to("/datadetail/"+item.id);
			},
			onSearch(){
				this.page=1;
				this.list=[];
				this.getdatalist();
			},
			
		}
	}
</script>

<style lang="scss">
	.database_list{
		background-color: inherit;
		.data_list{
			display: flex;
			flex-wrap: wrap;
			gap: 0.4rem;
			padding: 10px;
			.data_item{
				text-align: center;
				width: calc(calc(100% - 0.8rem)/3);
				padding: 0.4rem 0;
				background-color: #fff;
				border-radius: 5px;
			}
		}
	}
</style>