<template>
	<div class="mainpage">
		<van-nav-bar
		  :title="configdata.passtitle"
		  left-text=""
		  left-arrow
		  fixed
		  placeholder 
		  @click-left="$u.back"
		/>
		<div class="forget_page">
			<div v-html="configdata.passcontent" v-if="configdata.passcontent">
				
			</div>
		</div>
	</div>
</template>

<script>
	import Vue from 'vue';
	import {  Cell, CellGroup } from 'vant';
	
	Vue.use(Cell);
	Vue.use(CellGroup);
	export default{
		data(){
			return{
				configdata:{passtitle:'忘记密码'}
			}
		},
		mounted() {
			this.$u.http('/api/index/getconfigval',{body:{key:"passtitle,passcontent"}}).then(res=>{
				this.configdata=res.msg;
			})
		},
		methods:{
			
		}
	}
</script>

<style lang="scss">
	.forget_page{
		text-align: center;
		padding: 10px;
		*{
			margin: 0;
		}
	}
</style>