<template>
	<div class="mainpage" style="min-height: 100vh;height: auto;">
		<van-nav-bar
		  title="我的评论"
		  left-text=""
		  left-arrow
		  fixed
		  placeholder 
		  @click-left="$u.back"
		/>
		<div class="follow_page">
			<van-pull-refresh v-model="refreshing" @refresh="onRefresh">
				<van-list
				  v-model="loading"
				  :finished="finished"
				  finished-text="没有更多了"
				  @load="loadmore"
				>
				  <div class="my_comment">
					  <div class="my_comment_item" v-for="(item,index) in list" :key="index">
						  <div class="img_detail" @click="todetail(item,index)">
							  <van-image
								width="30"
								height="30"
								fit="cover"
								round
								:src="item.imgsrc"
							  />
							  <div style="margin-left: 5px;width: 70%;flex: 1;margin-right: 5px;">{{item.imgname}}</div>
							  <van-icon name="arrow" />
						  </div>
						  <div style="color: #aaa;font-size: 0.5rem;padding-left: 10px;padding-top: 5px;" v-if="item.reply_to_user_id==resdata.uid">{{item.to_username}}回复我</div>
						  <div class="mycomment_content">
							  <span>{{item.content}}</span>
						  </div>
						  <div class="comment_time">
							<span>{{$u.parseTime(item.created_at)}}</span>
							<van-button 
							style="margin-left: 10px;"
							type="info" size="mini" @click="childreply(item,index)" v-if="item.reply_to_user_id==resdata.uid">回复</van-button>
							<van-button 
							style="margin-left: 10px;"
							type="danger" size="mini" @click="delcomment(item,index)" v-if="item.user_id==resdata.uid">删除</van-button>

						  </div>
					  </div>
				  </div>
				</van-list>
			</van-pull-refresh>
		</div>
		<addcomment ref="addcomment" @completereply="completereply"/>
	</div>
</template>

<script>
	import Vue from 'vue';
	import datalistmixin from '@/assets/mixin/datalist.js';
	import addcomment from '@/components/addcomment.vue';
	import { Dialog } from 'vant';

	export default{
		components:{addcomment},
		mixins: [datalistmixin],
		data(){
			return{
				apiurl:"/api/getimg/getmycomments",
				query:{
					
				},
				islogin:1,
			}
		},
		mounted() {
			
		},
		methods:{
			delcomment(item,index){
				let self=this;
				let uinfo=this.$u.getitem('$uinfo')||{};
				if(!uinfo.token){
					this.$parent.showlogin();
					return;
				}
				Dialog.confirm({
				  title: '删除提示',
				  message: '确定删除此评论吗？',
				}).then(() => {
					self.dodel(item,index);
				}).catch(() => {
					// on cancel
				});
			},
			dodel(item,index){
				let uinfo=this.$u.getitem('$uinfo')||{};
				if(!uinfo.token){
					this.$parent.showlogin();
					return;
				}
				let formdata={
					token:uinfo.token,
					id:item.id,
				}
				this.$u.http("/api/getimg/delcomments",{body:formdata}).then(res=>{
					this.$u.toast(res.msg);
					if(res.status==200){
						this.list.splice(index,1);
					}
				}).catch(err=>{
					
				});
			},
			reply(item,index){
				this.comreply({item:item,index:index});
			},
			childreply(item,index){
				let dataitem={
					article_id:item.article_id,
					id:item.parent_id,
					uid:item.user_id,
					username:item.to_username
				};
				this.comreply({item:dataitem,index:index});
			},
			comreply(data){
				let uinfo=this.$u.getitem('$uinfo')||{};
				if(!uinfo.token){
					this.$parent.showlogin();
					return;
				}
				let {item,index}=data;
				this.$refs.addcomment.open({
					article_id:item.article_id,
					parent_id:item.id,
					reply_to_user_id:item.uid,
					parent_name:item.username
				});
			},
			completereply(){
				this.$u.totop();
				this.page=1;
				this.list=[];
				this.getdatalist();
			},
			todetail(item){
				this.$u.to("/imgdetail/"+item.zt_id+"/"+item.article_id);
			}
		}
	}
</script>

<style lang="scss">
.follow_page{
	.my_comment{
		padding: 0.5rem;
		.my_comment_item{
			background-color: #fff;
			border-radius: 0.5rem;
			margin-bottom: 10px;
		}
		.img_detail{
			display: flex;
			align-items: center;
			padding: 0.5rem;
			border-bottom: 1px solid #eee;
			font-size: 0.65rem;
		}
		.mycomment_content{
			padding: 0.5rem;
			font-size: 0.7rem;
		}
		.comment_time{
			font-size: 0.6rem;
			color: #999;
			padding: 0.4rem;
			display: flex;
			align-items: center;
			justify-content: end;
			border-top: 1px solid #eee;
		}
	}
}
</style>