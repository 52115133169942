<template>
	<div class="mainpage">
		<van-nav-bar
		  title="资料大全"
		  left-text=""
		  left-arrow
		  fixed
		  placeholder 
		  @click-left="$u.back"
		/>
		<div v-if="isloading" style="height: 100vh;padding: 10px 0;">
			<van-skeleton title :row="3" v-for="(item,index) in 5" style="margin-bottom: 10px;"/>
		</div>
		<div class="database_detail">
			<div class="data_title">
				<div class="title_txt">{{datainfo.title}}</div>
			</div>
			<div class="data_content">
				<div v-html="datainfo.content"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import Vue from 'vue';
	import { Search } from 'vant';
	Vue.use(Search);
	import { List ,PullRefresh } from 'vant';
	Vue.use(List).use(PullRefresh );
	export default{
		data(){
			return{
				datainfo: {},
				isloading:false,
				id:""
			}
		},
		mounted() {
			if(this.$route.params.id){
				this.id=this.$route.params.id;
				this.isloading=true;
				this.getdatalist().then(res=>{
					this.isloading=false;
				});
			}
		},
		methods:{
			getdatalist(){
				return new Promise((reponse,reject)=>{
					let formdata={
						id:this.id,
					}
					this.$u.http('/api/index/getzlkdetail',{body:formdata}).then(res=>{
						let datainfo = res.data;
						this.datainfo=datainfo;
						reponse(datainfo);
					}).catch(err=>{
						reject();
					})
				})
				
			}
		}
	}
</script>

<style lang="scss">
	.database_detail{
		background-color: inherit;
		.data_title{
			padding: 16px;
			margin-bottom: 10px;
			background-color: #fff;
		}
		.data_content{
			background-color: #fff;
			padding: 10px;
		}
	}
</style>