<template>
	<div class="sitecom_list">
		<div class="site_nav">
			<div class="site_navitem font15" :class="{active_site:dh_id==item.id}" v-for="(item,index) in catelist" :key="index">{{item.name}}</div>
		</div>
		<div class="site_list">
			<div class="site_item " v-for="(item,index) in datalist" @click="topage(item)">
				{{item.name}}
				<van-tag type="primary" v-if="item.tags&&item.tags.indexOf('荐')>-1">荐</van-tag>
				<van-tag type="success" v-if="item.tags&&item.tags.indexOf('精')>-1">精</van-tag>
				<van-tag type="danger" v-if="item.tags&&item.tags.indexOf('热')>-1">热</van-tag>
			</div>
		</div>
		<van-button plain type="info" round block @click="getmore">
		  点击查看更多网址<van-icon name="arrow-down" />
		</van-button>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				catelist:[],
				datalist:[],
				page:1,
				limit:15,
				dh_id:","
			}
		},
		created() {
			this.getcatelist();
		},
		methods:{
			getmore(){
				if(this.isend){
					return this.$u.toast("没有更多了")
				}
				this.page+=1;
				this.getdatalist();
			},
			topage(item){
				if(item.url){
					window.open(item.url);
				}
			},
			selnav(item){
				if(this.dh_id==item.id){return}
				this.page=1;
				this.dh_id=item.id;
				this.getdatalist();
			},
			getcatelist(){
				this.$u.http("/index/menu/getcate",{}).then(res=>{
					this.catelist=res.data;
					this.dh_id=this.catelist[0].id;
					this.getdatalist();
				})
			},
			getdatalist(){
				let formdata={
					id:this.dh_id,
					page:this.page,
					limit:this.limit,
				}
				this.$u.http("/index/menu/getdatalist",{body:formdata}).then(res=>{
					let datalist=res.data.data;
					if(datalist.length==0&&this.page!=1){
						this.isend=true;
						return this.$u.toast("没有更多了")
					}
					this.datalist = this.datalist.concat(datalist);
				})
			}
		}
	}
</script>

<style lang="scss">
	.sitecom_list{
		.site_nav{
			display: flex;
			align-items: center;
			border: .02rem solid #3e73fe;
			margin:  0.5rem 0;
			border-radius: 100px;
			height: 34px;
			.site_navitem{
				height: 100%;
				width: 25%;
				border-radius: 100px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			.active_site{
				background: #3e73fe;
				color: #fff;
			}
		}
		.site_list{
			display: flex;
			flex-wrap: wrap;
			gap: 0.5rem;
			padding:0.5rem 0;
			padding-top: 0;
		}
		.site_item{
			width: calc(calc(100% - 1rem)/3);
			display: flex;
			font-size: 0.51rem;
			align-items: center;
			justify-content: center;
			background-color: #f2f2f2;
			padding: 5px 0;
			border-radius: 5px;
			    color: #444;
		}
	}
</style>