import 'vant/lib/index.css';
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import tabbar from '@/components/tabbar.vue';

import {Button,DropdownMenu,DropdownItem,Switch,Field} from 'vant'
import { RadioGroup, Radio } from 'vant';
import { CheckboxGroup, Checkbox } from 'vant';
import { Tab, Tabs } from 'vant';
import { Tabbar, TabbarItem } from 'vant';
import { NavBar, Icon, Tag, Swipe, SwipeItem } from 'vant';
import { NoticeBar } from 'vant';

import { Cell, CellGroup } from 'vant';
import { ImagePreview } from 'vant';
import { Lazyload } from 'vant';
Vue.use(Lazyload);


import { Skeleton } from 'vant';

Vue.use(Skeleton);
// 全局注册
Vue.use(ImagePreview);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.component('tabbar', tabbar)
Vue.component('van-field', Field)
Vue.component('van-button', Button)
Vue.component('van-dropdown-menu', DropdownMenu)
Vue.component('van-dropdown-item', DropdownItem)
Vue.component('van-cell', Cell)
Vue.component('van-switch', Switch)
Vue.component('van-checkbox-group', CheckboxGroup)
Vue.component('van-checkbox', Checkbox)
Vue.component('van-radio-group', RadioGroup)
Vue.component('van-radio', Radio)
Vue.component('van-tab', Tab)
Vue.component('van-tabs', Tabs)
Vue.component('van-tabbar', Tabbar)
Vue.component('van-tabbar-item', TabbarItem)
Vue.component('van-nav-bar', NavBar)
Vue.component('van-icon', Icon)
Vue.component('van-tag', Tag)
Vue.component('van-swipe-item', SwipeItem)
Vue.component('van-swipe', Swipe)
import { Popup } from 'vant';

Vue.use(Popup);
import { Toast } from 'vant';

Vue.use(Toast);
Vue.use(NoticeBar);
Vue.config.productionTip = false
import utils from './assets/utils.js'
Vue.prototype.$u=utils;
Vue.prototype.mcolor='#dc0002';
Vue.prototype.configdefault={};
utils.getconfig().then(res=>{
	Vue.prototype.configdefault=res;
})
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
