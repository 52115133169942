<template>
	<div class="mainpage">
		<van-nav-bar
		  title="联系客服"
		  left-text=""
		  left-arrow
		  fixed
		  placeholder 
		  @click-left="$u.back"
		/>
		<div class="service_page">
			<div class="kefu_tip">
				<img  src="@/assets/imgs/kefu.png" width="120px"/>
				<div style="margin-left: 10px;">
					<div class="kf_title">有问题请找客服</div>
					<div class="kefu_desc">7*24小时在线服务</div>
				</div>
			</div>
			
			<div style="padding: 1rem;">
				<van-button type="info" color="#3e73fe" block :icon="kefuicon" @click="tokefu">在线客服</van-button>
			</div>

		</div>
	</div>
</template>

<script>
	import Vue from 'vue';
	import {  Cell, CellGroup } from 'vant';
	
	Vue.use(Cell);
	Vue.use(CellGroup);
	let kefuicon = require("@/assets/imgs/kficon.png");
	export default{
		data(){
			return{
				kefuicon:kefuicon,
				configdata:""
			}
		},
		mounted() {
			this.$u.http('/api/index/getconfigval',{body:{key:"kefuurl"}}).then(res=>{
				this.configdata=res.msg.data;
			})
		},
		methods:{
			tokefu(){
				if(this.configdata){
					window.location.href=this.configdata;
				}
			}
		}
	}
</script>

<style lang="scss">
	.service_page{
		background-color: #fff;
		.kefu_tip{
			display: flex;
			align-items: center;
			padding: 1rem;
			.kf_title{
				font-size: 1.2rem;
				font-weight: 500;
				color: #000;
			}
			.kefu_desc{
				margin-top: 0.5rem;
				font-size: 0.8rem;
				color: #888;
			}
		}
	}
</style>