<template>
	<div class="mainpage">
		<van-nav-bar
		  title="历史开奖"
		  left-text=""
		  left-arrow
		  fixed
		  placeholder 
		  @click-left="$u.back"
		/>
		<div class="nav_wrap">
			<van-tabs @change="changetype">
			  <van-tab title="新香港" name="1"></van-tab>
			  <van-tab title="香港" name="2"></van-tab>
			  <van-tab title="澳门" name="3"></van-tab>
			</van-tabs>
		</div>
		<div class="filter_wrap">
			<span>2024年历史开奖记录</span>
			<div class="filter_list">
				<div class="filter_item" @click="changeqi">
					期数
					<div>
						<van-icon name="arrow-up" size="12" :color="qitype==1?'#3e73fe':'#ddd'"/>
						<van-icon name="arrow-down"  size="12"  :color="qitype==2?'#3e73fe':'#ddd'"/>
					</div>
				</div>
				<div class="filter_item" @click="changema">
					平码
					<div>
						<van-icon name="arrow-up" size="12" :color="matype==1?'#3e73fe':'#ddd'"/>
						<van-icon name="arrow-down"  size="12"  :color="matype==2?'#3e73fe':'#ddd'"/>
					</div>
				</div>
				<div class="filter_item" @click="changewx">
					<span>五行</span>
					<div>
						<van-icon name="success"  :color="'#3e73fe'" v-if="wxtype==1"/>
					</div>
				</div>
			</div>
		</div>
		<div class="draw_list">
			<div v-if="isloading" style="height: 100vh;padding: 10px 0;">
				<van-skeleton title :row="3" v-for="(item,index) in 5" style="margin-bottom: 10px;"/>
			</div>
			<div v-if="!isloading">
				<div class="ball_wrap"  v-for="(item,index) in datalist" :key="index" v-if="datalist.length!=0">
					<div class="ball_time">
						<span>{{item.year}}年第<font color="red">{{item.no}}</font>期开奖结果</span>
						<span>{{item.day}}</span>
					</div>
					<div class="ball_list">
							<ballitem :dataitem="item" :matype="matype" :wxtype="wxtype"/>
							<!-- <div class="ball_item" :class="[getnuminfo(item1,'color')]">
								<div class="ball_num">{{item1}}</div>
								<div class="ball_label">
									<span>{{getnuminfo(item1,'sxiao')}}<span v-if="wxtype==1">/{{getnuminfo(item1,'zn')}}</span></span>
								</div>
							</div>
							<div v-if="index1==5">
								+
							</div> -->
						
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Vue from 'vue';
	import {  Cell, CellGroup } from 'vant';
	import numlist from "@/assets/numlist.js";
	import ballitem from '../../components/ballitem.vue';
	Vue.use(Cell);
	Vue.use(CellGroup);
	export default{
		components:{ballitem},
		data(){
			return{
				type:"",
				datalist:[],
				isloading:false,
				qitype:2,
				matype:3,
				wxtype:1,
			}
		},
		mounted() {
			if(this.$route.params.type){
				this.type=this.$route.params.type;
				this.getdatalist();
			}
		},
		methods:{
			changeqi(){
				this.qitype=this.qitype==1?2:1;
				if(this.qitype==1){
					this.datalist.sort((a,b)=>{return a.no - b.no});
				}
				if(this.qitype==2){
					this.datalist.sort((a,b)=>{return b.no - a.no});
				}
			},
			changema(){
				if(this.matype==3){
					this.matype=1;
				}else{
					this.matype+=1;
				}
			},
			changewx(){
				this.wxtype=this.wxtype==1?2:1;
			},
			getnumarr(item){
				let toparr=[item.p1,item.p2,item.p3,item.p4,item.p5,item.p6];
				if(this.matype==1){
					toparr=toparr.sort((a,b)=>{return a - b});
				}
				if(this.matype==2){
					toparr=toparr.sort((a,b)=>{return b - a});
				}
				return [...toparr,item.p7||item.t];
			},
			changetype(e){
				this.type=+e;
				this.datalist=[];
				this.getdatalist();
			},
			getnuminfo(num,key){
				let dataitem = numlist[num+''];
				if(dataitem){
					return dataitem[key]||'';
				}else{
					return ''
				}
			},
			getdatalist(){
				let type=this.type;
				let arr=[
					"/Index/getdrawhistory",
					'https://wqq1w.jjdwq.xyz/Index/lotteryList?lotteryType=1&a=year',
					'https://wqq1w.jjdwq.xyz/Index/lotteryList?lotteryType=2&a=year'
				];
				let header={
					accept:'application/json'
				}
				this.isloading=true;
				this.$u.http(arr[type-1],{headers:header}).then(res=>{
					this.datalist=res.data;
					this.isloading=false;
				})
			}
		}
	}
</script>

<style lang="scss">
	.filter_wrap{
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px;
		background-color: transparent;
		font-size: 0.55rem;
		.filter_list{
			display: flex;
			align-items: center;
			.filter_item{
				display: flex;
				align-items: center;
				background-color: #fff;
				border-radius: 30px;
				margin-right: 10px;
				padding: 3px 8px;
				color: $mcolor1;
				div{
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
				}
			}
		}
	}
	.nav_wrap{
		padding: 0 0;
		border-bottom: 1px solid #ddd;
		position: sticky;
		top: 2rem;
		z-index: 11;
	}
	.draw_list{
		background-color: #fff;
		.ball_wrap{
			padding: 0.4rem;
			margin-top: 0.3rem;
			border-bottom: 1px solid #ddd;
			.ball_time{
				display: flex;
				align-items: center;
				justify-content: space-between;
				font-size: 0.6rem;
				font-weight: bold;
				margin-bottom: 0.3rem;
			}
			.ball_list{
				
			}
		}
	}
</style>