const numlist = {
  "01": { "color": "red", "sxiao": "龙", "wx": "wx-huo", "zn": "火" },
  "02": { "color": "red", "sxiao": "兔", "wx": "wx-jin", "zn": "金" },
  "03": { "color": "blue", "sxiao": "虎", "wx": "wx-jin", "zn": "金" },
  "04": { "color": "blue", "sxiao": "牛", "wx": "wx-tu", "zn": "土" },
  "05": { "color": "green", "sxiao": "鼠", "wx": "wx-tu", "zn": "土" },
  "06": { "color": "green", "sxiao": "猪", "wx": "wx-mu", "zn": "木" },
  "07": { "color": "red", "sxiao": "狗", "wx": "wx-mu", "zn": "木" },
  "08": { "color": "red", "sxiao": "鸡", "wx": "wx-huo", "zn": "火" },
  "09": { "color": "blue", "sxiao": "猴", "wx": "wx-huo", "zn": "火" },
  "10": { "color": "blue", "sxiao": "羊", "wx": "wx-jin", "zn": "金" },
  "11": { "color": "green", "sxiao": "马", "wx": "wx-jin", "zn": "金" },
  "12": { "color": "red", "sxiao": "蛇", "wx": "wx-shui", "zn": "水" },
  "13": { "color": "red", "sxiao": "龙", "wx": "wx-shui", "zn": "水" },
  "14": { "color": "blue", "sxiao": "兔", "wx": "wx-mu", "zn": "木" },
  "15": { "color": "blue", "sxiao": "虎", "wx": "wx-mu", "zn": "木" },
  "16": { "color": "green", "sxiao": "牛", "wx": "wx-huo", "zn": "火" },
  "17": { "color": "green", "sxiao": "鼠", "wx": "wx-huo", "zn": "火" },
  "18": { "color": "red", "sxiao": "猪", "wx": "wx-tu", "zn": "土" },
  "19": { "color": "red", "sxiao": "狗", "wx": "wx-tu", "zn": "土" },
  "20": { "color": "blue", "sxiao": "鸡", "wx": "wx-shui", "zn": "水" },
  "21": { "color": "green", "sxiao": "猴", "wx": "wx-shui", "zn": "水" },
  "22": { "color": "green", "sxiao": "羊", "wx": "wx-mu", "zn": "木" },
  "23": { "color": "red", "sxiao": "马", "wx": "wx-mu", "zn": "木" },
  "24": { "color": "red", "sxiao": "蛇", "wx": "wx-jin", "zn": "金" },
  "25": { "color": "blue", "sxiao": "龙", "wx": "wx-jin", "zn": "金" },
  "26": { "color": "blue", "sxiao": "兔", "wx": "wx-tu", "zn": "土" },
  "27": { "color": "green", "sxiao": "虎", "wx": "wx-tu", "zn": "土" },
  "28": { "color": "green", "sxiao": "牛", "wx": "wx-shui", "zn": "水" },
  "29": { "color": "red", "sxiao": "鼠", "wx": "wx-shui", "zn": "水" },
  "30": { "color": "red", "sxiao": "猪", "wx": "wx-huo", "zn": "火" },
  "31": { "color": "blue", "sxiao": "狗", "wx": "wx-huo", "zn": "火" },
  "32": { "color": "green", "sxiao": "鸡", "wx": "wx-jin", "zn": "金" },
  "33": { "color": "green", "sxiao": "猴", "wx": "wx-jin", "zn": "金" },
  "34": { "color": "red", "sxiao": "羊", "wx": "wx-tu", "zn": "土" },
  "35": { "color": "red", "sxiao": "马", "wx": "wx-tu", "zn": "土" },
  "36": { "color": "blue", "sxiao": "蛇", "wx": "wx-mu", "zn": "木" },
  "37": { "color": "blue", "sxiao": "龙", "wx": "wx-mu", "zn": "木" },
  "38": { "color": "green", "sxiao": "兔", "wx": "wx-huo", "zn": "火" },
  "39": { "color": "green", "sxiao": "虎", "wx": "wx-huo", "zn": "火" },
  "40": { "color": "red", "sxiao": "牛", "wx": "wx-jin", "zn": "金" },
  "41": { "color": "blue", "sxiao": "鼠", "wx": "wx-jin", "zn": "金" },
  "42": { "color": "blue", "sxiao": "猪", "wx": "wx-shui", "zn": "水" },
  "43": { "color": "green", "sxiao": "狗", "wx": "wx-shui", "zn": "水" },
  "44": { "color": "green", "sxiao": "鸡", "wx": "wx-mu", "zn": "木" },
  "45": { "color": "red", "sxiao": "猴", "wx": "wx-mu", "zn": "木" },
  "46": { "color": "red", "sxiao": "羊", "wx": "wx-huo", "zn": "火" },
  "47": { "color": "blue", "sxiao": "马", "wx": "wx-huo", "zn": "火" },
  "48": { "color": "blue", "sxiao": "蛇", "wx": "wx-tu", "zn": "土" },
  "49": { "color": "green", "sxiao": "龙", "wx": "wx-tu", "zn": "土" }
};
export default numlist;