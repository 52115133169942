<template>
	<div style="padding: 10px;border-top: 1px solid #eee;background-color: #fff;" v-if="getkjnumm(qi).p1">
		<ballitem :dataitem="getkjnumm(qi)" :matype="3" :wxtype="1"/>
	</div>
</template>

<script>
	import ballitem from '@/components/imgballitem.vue';
	export default {
		components:{ballitem},
		props:['qi'],
		data(){
			return{
				drawlist:[],
				type:1,
			}
		},
		mounted() {
			this.getdatalist();
		},
		methods:{
			getkjnumm(qi){
				var qi=qi+'';
				let kjarr = this.drawlist.filter(item=>{
					return qi.indexOf(item.number+'')>-1;
				});
				if(kjarr.length!=0){
					return kjarr[0];
				}else{
					return {};
				}
			},
			getdatalist(){
				let type=this.type;
				let arr=[
					"/Index/getdrawhistory",
					'https://wqq1w.jjdwq.xyz/Index/lotteryList?lotteryType=1&a=year',
					'https://wqq1w.jjdwq.xyz/Index/lotteryList?lotteryType=2&a=year'
				];
				let header={
					accept:'application/json'
				}
				this.$u.http(arr[type-1],{headers:header}).then(res=>{
					this.drawlist=res.data;
				})
			},
		}
	}
</script>

<style>
</style>