<template>
	<div class="ball_com">
		<template  v-for="(item1,index1) in getnumarr(dataitem)">
			<div class="ball_item" :class="[getnuminfo(item1,'color')]">
				<div class="ball_num">{{item1}}</div>
				<div class="ball_label">
					<span>{{getnuminfo(item1,'sxiao')}}<span v-if="wxtype==1">/{{getnuminfo(item1,'zn')}}</span></span>
				</div>
			</div>
			<div v-if="index1==5">
				+
			</div>
		</template>
	</div>
</template>

<script>
	import numlist from "@/assets/numlist.js";
	export default{
		props:['dataitem','matype','wxtype'],
		data(){
			return{
				
			}
		},
		mounted() {
			
		},
		methods:{
			getnumarr(item){
				let toparr=[item.p1,item.p2,item.p3,item.p4,item.p5,item.p6];
				if(this.matype==1){
					toparr=toparr.sort((a,b)=>{return a - b});
				}
				if(this.matype==2){
					toparr=toparr.sort((a,b)=>{return b - a});
				}
				return [...toparr,item.p7||item.t];
			},
			getnuminfo(num,key){
				let dataitem = numlist[num+''];
				if(dataitem){
					return dataitem[key]||'';
				}else{
					return ''
				}
			},
		}
	}
</script>

<style lang="scss">
	.ball_com{
		display: flex;
		align-items: center;
		gap: 0.2rem;
		.green {
		    background-color: #1fb61d
		}
		.blue {
		    background-color: #2389e9
		}
		.red {
		    background-color: #e92323
		}
		.ball_item{
			width: 1.96rem;
			padding: 0.1rem 0.1rem;
			text-align: center;
			box-sizing: border-box;
			border-radius: 8px;
		}
		.ball_num{
			font-size: 1rem;
			font-weight: bold;
			color: #fff;
		}
		.ball_label{
			background-color: #fff;
			padding: 0.2rem;
			width: 100%;
			font-size: 0.5rem;
			text-align: center;
			border-radius: 8px;
		}
	}
</style>