<template>
	<div class="mainpage">
		<van-nav-bar
		  title="我的反馈"
		  left-text=""
		  right-text="我要反馈"
		  left-arrow
		  fixed
		  placeholder 
		  @click-left="$u.back"
		  @click-right="onClickRight"
		/>
		<div class="feed_list">
			<div class="feed_item">
				<van-pull-refresh v-model="refreshing" @refresh="onRefresh">
					<van-list
					  v-model="loading"
					  :finished="finished"
					  :finished-text="list.length>1?'没有更多了':''"
					  @load="loadmore"
					>
					  <div class="data_list">
						  <div class="data_item font25" v-for="(item,index) in list" :key="index" @click="todetail(item)">
							  <div class="dataitem_top">
								  <span>标题：{{item.title}}</span>
								  <van-tag type="primary" v-if="item.reply">查看回复</van-tag>
								  <van-tag type="danger" v-if="!item.reply">未回复</van-tag>
							  </div>
							  <div class="dataitem_bot">
								  <div class="data_content">内容：{{item.content}}</div>
								  <span>{{$u.parseTime(item.createtime)}}</span>
							  </div>
						  </div>
					  </div>
					</van-list>
				</van-pull-refresh>
			</div>
			<van-empty description="暂无数据" v-if="list.length==0&&!loading">
			  <van-button round type="info" class="info_btn" @click="onClickRight">我要反馈</van-button>
			</van-empty>
		</div>
	</div>
</template>

<script>
	import Vue from 'vue';
	import { NavBar } from 'vant';
	import { Empty } from 'vant';
	import datalistmixin from '@/assets/mixin/datalist.js';
	Vue.use(Empty);
	Vue.use(NavBar);
	export default{
		mixins: [datalistmixin],
		data(){
			return{
				apiurl:"/api/feedback/getdatalist",
				islogin:true,
			}
		},
		
		mounted() {
			
		},
		methods:{
			todetail(item){
				this.$u.to("/feeddetail/"+item.id);
			},
			onClickRight(){
				let uinfo=this.$u.getitem('$uinfo')||{};
				if(!uinfo.token){
					this.$parent.showlogin();
					return;
				}
				this.$u.to('/addfeedback');
			}
		}
	}
</script>

<style lang="scss">
	.feed_list{
		.data_list{
			padding: 0.3rem;
			.data_item{
				padding: 0.4rem;
				border-radius: 0.2rem;
				background-color: #fff;
				margin-bottom: 0.4rem;
				font-size: 0.65rem;
				.dataitem_top{
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding-bottom: 0.3rem;
				}
				.dataitem_bot{
					display: flex;
					align-items: center;
					justify-content: space-between;
					.data_content{
						flex: 1;
						width: 50%;
						padding-right: 10px;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
					}
				}
			}
		}
		.info_btn{
			background-color: $mcolor1;
			font-size: 0.8rem;
			font-weight: bold;
			line-height: 1.5;
			height: auto;
			padding: 0.3rem 0.8rem !important;
		}
	}
</style>