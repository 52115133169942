import Vue from 'vue';
import VueRouter from 'vue-router';
import lanhu_shouye2 from '../views/index.vue';
import person from '../views/person.vue';
import feedback from '../views/feedback.vue';
import addfeedback from '../views/subpage/addfeedback.vue';
import aboutus from '../views/subpage/aboutus.vue';
import service from '../views/subpage/service.vue';
import sitemsg from '../views/subpage/sitemsg.vue';
import official from '../views/subpage/sitemsg/official.vue';
import system from '../views/subpage/sitemsg/system.vue';
import login from '../views/subpage/login.vue';
import forget from '../views/subpage/forget.vue';
import register from '../views/subpage/register.vue';

import collection from '../views/subpage/collection.vue';
import comment from '../views/subpage/comment.vue';
import follow from '../views/subpage/follow.vue';
import mylikes from '../views/subpage/mylikes.vue';
import imgdetail from '../views/subpage/imgdetail.vue';
import draws from '../views/subpage/draws.vue';
import database from '../views/subpage/database.vue';
import datadetail from '../views/subpage/datadetail.vue';
import website from '../views/subpage/website.vue';
import imglist from '../views/subpage/imglist.vue';
import imgsearch from '../views/subpage/imgsearch.vue';
import feeddetail from '../views/subpage/feeddetail.vue';
import votelist from '../views/subpage/votelist.vue';

Vue.use(VueRouter)

const routes = [
    {
    path: '/',
    component: lanhu_shouye2,
	meta: { keepAlive: true }
  },
    {
    path: '/person',
    component: person,
	meta: { keepAlive: true }
  },
    {
    path: '/feedback',
    component: feedback
  },
    {
    path: '/addfeedback',
    component: addfeedback
  },
    {
    path: '/aboutus',
    component: aboutus
  },
    {
    path: '/service',
    component: service
  },
    {
    path: '/sitemsg',
    component: sitemsg
  },
    {
    path: '/official',
    component: official
  },
    {
    path: '/system',
    component: system
  },
    {
    path: '/login',
    component: login
  },
    {
    path: '/register',
    component: register
  },
  {
    path: '/forget',
    component: forget
  },
  {
    path: '/collection',
    component: collection
  },
  {
    path: '/comment',
    component: comment
  },
  {
    path: '/follow',
    component: follow
  },
  {
    path: '/mylikes',
    component: mylikes
  },
  {
    path: '/imgdetail/:id/:pid?',
    component: imgdetail
  },
  {
    path: '/draws/:type',
    component: draws
  },
  {
    path: '/database',
    component: database,
	meta: { keepAlive: true }
  },
  {
    path: '/datadetail/:id',
    component: datadetail
  },
  {
    path: '/website',
    component: website,
	meta: { keepAlive: true }
  },
  {
    path: '/imglist',
    component: imglist,
	meta: { keepAlive: true }
  },
  {
    path: '/imgsearch',
    component: imgsearch,
	meta: { keepAlive: true }
  },
  {
    path: '/feeddetail/:id',
    component: feeddetail
  },
  {
    path: '/votelist/:id/:qi/:year',
    component: votelist
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
});
router.afterEach((to, from, next) => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
 
    // 兼容IE
    window.scrollTo(0, 0);
    next&&next();
})
export default router
