<template>
	<div>
		<van-tabbar :before-change="beforeTabChange" active-color="rgb(220, 0, 2)" fixed route>
		  <van-tabbar-item to="/" icon="home-o">809图库</van-tabbar-item>
		  <van-tabbar-item to="/database" icon="bulb-o">发现
			    <template #icon="props">
					<van-icon name="bulb-o" size="28px"/>
				</template>
		  </van-tabbar-item>
		  <van-tabbar-item icon="shop" to="/website">
				<div style="font-size: 12px;">网址大全</div>
				<template #icon="props">
					<span style="opacity: 0;">1</span>
					<div style="position: absolute;bottom: 0;left: 50%;transform: translateX(-50%);z-index: 1;">
						<div style="width: 40px;height: 40px;border-radius: 50%;border: 5px solid #fff;">
							<div style="width: 100%;height: 100%;border-radius: 50%;background-color: #dc0002;display: flex;align-items: center;justify-content: center;">
								<van-icon name="gem-o" size="22px" color="#fff"/>
							</div>
						</div>
					</div>
				</template>
		  </van-tabbar-item>
		  <van-tabbar-item icon="shop">淘料市场</van-tabbar-item>
		  <van-tabbar-item to="/person" icon="user-o" :dot="msgcount!=0">我的</van-tabbar-item>
		</van-tabbar>
	</div>
</template>

<script>
	import { Toast } from 'vant';
	export default{
		props:{
			count:{
				type:[String,Number],
				default:0
			}
		},
		watch:{
			count(val){
				if(val){
					this.msgcount=val;
				}
			}
		},
		data(){
			return{
				msgcount:this.count,
			}
		},
		activated(){
			this.getdata();
		},
		mounted() {
			this.getdata();
		},
		destroyed() {
			if(this.timer){
				clearInterval(this.timer);
			}
		},
		methods:{
			getdata(){
				let uinfo=this.$u.getitem('$uinfo')||{};
				if(!uinfo.token){
					return;
				}else{
					if(this.timer){
						clearInterval(this.timer);
					}
					this.timer = setInterval(()=>{
						this.getunread();
					},2000);
				}
			},
			getunread(){
				let uinfo=this.$u.getitem('$uinfo')||{};
				this.$u.http("/api/getimg/getunread",{body:{token:uinfo.token}}).then(res=>{
					let feedcount = res.feedcount;
					this.msgcount=feedcount+res.data;
				})
			},
			beforeTabChange(index) {
			  if (index === 0) {
			    return true
			  } else if (index === 1) {
			    return true
			  }else if (index === 2) {
			    return true
			  }else if (index === 4) {
			    return true
			  } else {
			    Toast('开发中')
			    return false
			  }
			}
		}
	}
</script>

<style lang="scss">
	
</style>