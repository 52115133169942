<template>
	<div class="imgball_com">
		<template  v-for="(item1,index1) in getnumarr(dataitem)">
			<div class="ball_item">
				<div class="ball_num">
					<img class="ball_bg" :src="imginfo[getnuminfo(item1,'color')]" mode="widthFix"></img>
					<div class="bull_text">{{item1}}</div>
				</div>
				<div class="ball_label">
					<span>{{getnuminfo(item1,'sxiao')}}<span v-if="wxtype==1">/{{getnuminfo(item1,'zn')}}</span></span>
				</div>
			</div>
			<div v-if="index1==5" style="align-self: flex-start;padding-top: 15px;font-size: 20px;color: #999999;">
				+
			</div>
		</template>
	</div>
</template>

<script>
	import numlist from "@/assets/numlist.js";
	export default{
		props:['dataitem','matype','wxtype'],
		data(){
			return{
				imginfo:{
					red:require('@/assets/imgs/red.png'),
					blue:require('@/assets/imgs/blue.png'),
					green:require('@/assets/imgs/green.png'),
				}
			}
		},
		mounted() {
			
		},
		methods:{
			getnumarr(item){
				let toparr=[item.p1,item.p2,item.p3,item.p4,item.p5,item.p6];
				if(this.matype==1){
					toparr=toparr.sort((a,b)=>{return a - b});
				}
				if(this.matype==2){
					toparr=toparr.sort((a,b)=>{return b - a});
				}
				return [...toparr,item.p7||item.t];
			},
			getnuminfo(num,key){
				let dataitem = numlist[num+''];
				if(dataitem){
					return dataitem[key]||'';
				}else{
					return ''
				}
			},
		}
	}
</script>

<style lang="scss">
	.imgball_com{
		display: flex;
		align-items: center;
		gap: 0.2rem;
		.green {
		    background-color: #1fb61d
		}
		.blue {
		    background-color: #2389e9
		}
		.red {
		    background-color: #e92323
		}
		.ball_item{
			width: 1.96rem;
			padding: 0.1rem 0.1rem;
			text-align: center;
			box-sizing: border-box;
			border-radius: 8px;
		}
		.ball_num{
			font-weight: bold;
			color: #fff;
			position: relative;
			line-height: 1;
			.ball_bg{
				width: 100%;
				height: 100%;
			}
			.bull_text{
				position: absolute;
				font-size: 0.8rem;
				box-sizing: border-box;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #000;
			}
		}
		.ball_label{
			padding: 0.2rem;
			width: 100%;
			font-size: 0.5rem;
			text-align: center;
			border-radius: 8px;
		}
	}
</style>