<template>
	<div class="mainpage">
		<van-nav-bar
		  title="官方消息"
		  left-text=""
		  left-arrow
		  fixed
		  placeholder 
		  @click-left="$u.back"
		/>
		<div class="systemmsg_page">
			<van-pull-refresh v-model="refreshing" @refresh="onRefresh">
				<van-list
				  v-model="loading"
				  :finished="finished"
				  finished-text="没有更多了"
				  @load="loadmore"
				>
				  <div class="msg_list">
					  <div class="msg_item" v-for="(item,index) in list" :key="index">
						  <div class="msg_title">
							  <div class="msg_logo">
								  <van-image
								  	width="100%"
								  	height="100%"
								  	fit="cover"
								  	:src="configdata.logo" v-if="configdata.logo"
								  />
							  </div>
							  <div style="margin-left: 10px;">
								  <div class="msg_name">系统消息</div>
								  <div class="msg_time">{{$u.parseTime(item.createtime)}}</div>
							  </div>
						  </div>
						  <div class="msg_content">
						  		<span v-html="item.content" style="white-space: pre-wrap;"></span>
						  </div>
					  </div>
				  </div>
				</van-list>
			</van-pull-refresh>
		</div>
	</div>
</template>

<script>
	import Vue from 'vue';
	import datalistmixin from '@/assets/mixin/datalist.js';
	export default{
		mixins: [datalistmixin],
		data(){
			return{
				apiurl:"/api/index/getnotice",
				query:{
					type:'2'
				},
				configdata:this.configdefault
			}
		},
		mounted() {
			this.$u.getconfig().then(res=>{
				this.configdata=res;
			})
		},
		methods:{
			
		}
	}
</script>

<style lang="scss">
	.systemmsg_page{
		.msg_list{
			.msg_item{
				background-color: #fff;
				border-radius: 0.2rem;
				position: relative;
				padding: 0.2rem 0;
				border-bottom: 1px solid #eee;
				.msg_tag{
					position: absolute;
					top: 0;
					right: 0;
					padding:0.2rem 0.4rem ;
					border-radius: 0.1rem 0.5rem;
					background: #07c165;
					color: #fff;
					font-size: 0.5rem;
				}
				.tag_over{
					background: #bbb;
				}
				.msg_title{
					padding: 0.5rem;
					display: flex;
					align-items: center;
					.msg_logo{
						width: 48px;
						height: 48px;
						img{
							width: 100%;
							height: 100%;
							border-radius: 50%;
						}
					}
					.msg_name{
						font-size: 0.75rem;
						font-style: normal;
						font-weight: 600;
						color: $mcolor1;
					}
					.msg_time{
						font-size: 0.55rem;
						color: #888;
						margin-top: 0.2rem;
					}
				}
				.msg_content{
					padding: 0.5rem;
					padding-top: 0;
					color: #333;
					font-size: 0.6rem;
				}
			}
		}
	}
</style>