<template>
	<div class="mainpage">
		<van-nav-bar
		  title="反馈详情"
		  left-text=""
		  left-arrow
		  fixed
		  placeholder 
		  @click-left="$u.back"
		/>
		<div style="padding: 10px;">
			<van-panel title="标题:" desc="" status="">
			  <div style="padding: 0.5rem;" class="font25">
				  {{datainfo.title||''}}
			  </div>
			</van-panel>
			<van-panel title="反馈内容：" desc="" status="">
			  <div style="padding: 0.5rem;" class="font25">
				 {{datainfo.content||''}}
			  </div>
			</van-panel>
			<van-panel title="图片：" desc="" status="" v-if="datainfo.img">
			  <div>
				 <div class="img_item">
					<van-image
					  width="100%"
					  height="100%"
						fit="cover"
					  :src="$u.domain+datainfo.img"
					/>
				 </div>
			  </div>
			</van-panel>
			<van-panel title="回复:" desc="" status="">
			  <div style="padding: 0.5rem;" class="font25">
				 {{datainfo.reply||'未回复'}}
			  </div>
			</van-panel>
		</div>
	</div>
</template>

<script>
	import Vue from 'vue';
	import { NavBar,Panel,Uploader,Form  } from 'vant';
	Vue.use(NavBar).use(Panel).use(Uploader).use(Form);
	export default{
		data(){
			return{
				datainfo:{},
				loading :false,
				id:""
			}
		},
		mounted() {
			if(this.$route.params.id){
				this.id=this.$route.params.id;
				this.getdatalist();
			}
		},
		methods:{
			getdatalist() {
				let uinfo=this.$u.getitem('$uinfo')||{};
				if(!uinfo.token){
					this.$parent.showlogin();
					return;
				}
				let formdata={
					id:this.id,
					token:uinfo.token
				}
				this.$u.http("/api/feedback/detail",{body:formdata}).then(res=>{
					if(res.status==200){
						this.datainfo=res.data;
					}
				}).catch(err=>{
					
				});
			},
		}
	}
</script>

<style lang="scss">
	
</style>