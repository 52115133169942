<template>
  <div id="app">
	   <keep-alive>
		<router-view v-if="$route.meta.keepAlive" />
	  </keep-alive>
	  <!-- 非缓存的页面直接渲染 -->
	  <router-view v-if="!$route.meta.keepAlive" />
	<van-popup v-model="isshowlogin" closeable :close-on-click-overlay="false">
		<div class="login_pop">
			<div class="login_logo">
				<img :src="configdata.logo" v-if="configdata.logo"/>
			</div>
			<div class="login_form_item">
				<van-field v-model="loginform.username"  maxlength="11" type="number"  label="" placeholder="输入您手机号码" />
			</div>
			<div class="login_form_item">
				<van-field v-model="loginform.password" type="password" maxlength="20" label="" placeholder="输入您的密码" />
			</div>
			<div class="font25 mcolor" style="text-align: right;padding-top: 10px;" @click="toforget">忘记密码</div>
			<div class="loginpop_btn">
				<van-button type="info" block class="log_btn" @click="dologin">登录</van-button>
				<div class="font3 mcolor" style="padding: 20px 0;" @click="toregister">注册</div>
			</div>
		</div>
	</van-popup>
  </div>
</template>

<script>

export default {
	name: 'App',
	data(){
		return{
			isshowlogin:false,
			loginform:{
				username:"",
				password:""
			},
			configdata:this.configdefault,
			cb:null,
		}
	},
	mounted() {
		this.$u.getconfig().then(res=>{
			this.configdata=res;
		})
	},
	methods: {
		dologin(){
			this.$u.login(this.loginform).then(res=>{
				this.isshowlogin=false;
				this.loginform={
					username:"",
					password:""
				};
				if(this.cb){
					this.cb();
				}
			}).catch(err=>{
				
			})
		},
		toforget(){
			this.isshowlogin=false;
			this.$u.to("/forget");
		},
		toregister(){
			this.isshowlogin=false;
			this.$u.to("/register");  
		},
		showlogin(cb){
			this.cb=cb;
			this.isshowlogin=true;
		}
	},
}

</script>
<style lang="scss">
	.login_pop{
		text-align: center;
		width: 14rem;
		background-color: #fff;
		overflow: hidden;
		padding: 1rem;
		.login_logo{
			width: 92px;
			height: 92px;
			overflow: hidden;
			margin: 0 auto;
			img{
				width: 100%;
			}
		}
		.login_form_item{
			padding: 0.1rem 0;
			border-bottom: 1px solid #eee;
			margin-top: 20px;
		}
		.loginpop_btn{
			margin-top: 20px;
			padding-bottom: 50px;
			.log_btn{
				height: 50px;
				background-color: $mcolor2;
			}
		}
	}
</style>
<style lang="css" src="./assets/common.css">
</style>
<style>
	body {
	    font-family: -apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Segoe UI,Arial,Roboto,PingFang SC,miui,Hiragino Sans GB,Microsoft Yahei,sans-serif
	}
	@media screen and (max-width: 768px) {
	  ::-webkit-scrollbar {
	      display: none;
	  }
	}
	@media screen and (min-width: 769px) {
	  /* 定义全局滚动条样式 */
	  ::-webkit-scrollbar {
	      width: 6px;
		  height: 6px;
	  	 scrollbar-width: thin;
	  	 scrollbar-height: thin;
	  }
	  ::-webkit-scrollbar-track {
	      background: #eee;
	      border-radius: 10px;
	  }
	  
	  ::-webkit-scrollbar-thumb {
	      background: #bbb;
	      border-radius: 10px;
	  }
	  
	  ::-webkit-scrollbar-thumb:hover {
	      background: #aaa;
	  }
	}
	html{
		--main-color:#dc0002;
	}
	.van-index-anchor{
		color: var(--main-color);
		font-size: 0.7rem;
		font-weight: bold;
	}
	.mainpage{
		width: 100%;
		margin: auto;
		max-width: 488px;
	}
	.van-tabbar,.van-nav-bar,.van-overlay,.van-popup--top,.bot_wrap{
		left: 50%;
		transform: translateX(-50%);
		max-width: 488px !important;
	}
	.van-nav-bar__content,.van-nav-bar__placeholder {
	    height: 2rem !important;
	}
	.van-nav-bar {
	    background-color: var(--main-color) !important;
	}
	.van-nav-bar__title{
		    font-weight: 700 !important;
		color: #fff !important;
		font-size: 0.8rem;
	}
	.van-nav-bar .van-icon{
		color: #fff !important;
	}
	.van-nav-bar__text{
		color: #fff !important;
	}
	
	.van-panel__header{
		font-size: 0.7rem;
		padding-bottom: 0;
	}
	.sitemsg_page .van-cell{
		align-items: center;
	}
	.sitemsg_page .van-cell__value{
		color: #333;
	}
	.login_page .van-nav-bar,.register_page .van-nav-bar{
	    background-color: #d8e3ff!important
	}
	.login_page .van-icon,.register_page .van-icon{
	    color: #333 !important;
		font-size: 1rem;
	}
	.van-tab--active .van-tab__text{
		font-weight: bold;
	}
	.font1{
		font-size: 0.5rem;
	}
	.font15{
		font-size: 0.55rem;
	}
	.font2{
		font-size: 0.6rem;
	}
	.font25{
		font-size: 0.65rem;
	}
	.font3{
		font-size: 0.7rem;
	}
	.font35{
		font-size: 0.75rem;
	}
	.font4{
		font-size: 0.8rem;
	}
	.font45{
		font-size: 0.85rem;
	}
	.font5{
		font-size: 0.9rem;
	}
	.font5{
		font-size: 1rem;
	}
	.set_popwrap{
		background-color: transparent;
	}
	.login_pop .van-popup__close-icon{
		bottom: -40px;
	}
	.qishu_wrap .van-dropdown-menu__bar{
		height: auto;
		box-shadow: none;
		padding: 4px 0;
	}
	.qishu_wrap .van-ellipsis{
		color: var(--main-color);
	}
	.qishu_wrap .van-dropdown-menu__title::after{
		color: var(--main-color);
		border-color: transparent transparent var(--main-color) var(--main-color);
	}
	.site_wrap .van-tag{
		position: static;
	}
	.data_content *{
		margin: 0;
	}
	.filter_item .van-icon{
		margin-bottom: -4px;
	}
	.flex_center{
		display: flex;
		align-items: center;
	}
	.van-search__action:active{
		background-color: transparent;
	}
</style>
