import Vue from 'vue';
import { List ,PullRefresh } from 'vant';
Vue.use(List).use(PullRefresh );
export default {
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      page:1,
	  limit:20,
	  kw:"",
	  resdata:{}
    };
  },
  created() {
    
  },
  methods: {
    onRefresh() {
      this.finished = false;
	  this.loading = true;
      this.page=1;
      this.list=[];
      this.getdatalist().then(res=>{
		this.refreshing  = false;
    	this.loading = false;
	  });
    },
    loadmore() {
    	this.getdatalist().then(res=>{
    		this.loading = false;
    		if (res.length == 0) {
    		  this.finished = true;
    		}
    	});
    },
    getdatalist(){
    	return new Promise((reponse,reject)=>{
    		let formdata={
    			page:this.page,
    			limit:this.limit,
    		}
			let uinfo=this.$u.getitem('$uinfo')||{};
			if(this.islogin==1){
				formdata.token=uinfo.token;
			}
			formdata = Object.assign(formdata,this.query);
    		if(this.kw){
    			formdata.keyword=this.kw;
    		}
    		this.$u.http(this.apiurl,{body:formdata}).then(res=>{
    			let datalist = res.data.data;
				this.resdata = res;
    			if(datalist.length>0){
    				this.page+=1;
    			}else{
    				if(this.page>1){
    					this.page-=1;
    				}
    			}
    			this.list=this.list.concat(datalist);
    			reponse(datalist);
    		}).catch(err=>{
    			reject();
    		})
    	})
    	
    }
  },
};
