<template>
	<div class="sticky_top">
		<div class="year_list_wrap">
			<div class="year_list">
				<div class="year_item font2" :class="{active_year:curyear==item.year}" 
					v-for="(item,index) in yearlist" @click="selyear(item)">
					{{item.year}}年
				</div>
			</div>
		</div>
		<div class="qishu_wrap">
			<div class="qishu_list" ref="scrollx_wrap">
				<div class="qishu_item font15" 
					:id="['qishu'+item.qi]"
					:class="{active_qishu:curqishu==item.qi}" 
					v-for="(item,index) in qishulist"
					@click="selqishu(item)"
					>第{{getqi(item)}}期</div>
			</div>
			<div style="flex: 1;display: flex;align-items: center;justify-content: center;">
				<van-dropdown-menu>
				  <van-dropdown-item title="选择期数" ref="item">
				    <div class="qishu_morelist">
						<div class="qishu_item font15" 
							:class="{active_qishu:curqishu==item.qi}" 
							v-for="(item,index) in qishulist"
							@click="selqishu(item,1)"
							>第{{getqi(item)}}期</div>
				    </div>
				  </van-dropdown-item>
				</van-dropdown-menu>
			</div>
		</div>
	</div>
</template>

<script>
	import Vue from 'vue';
	import { DropdownMenu, DropdownItem } from 'vant';
	import drawitem from '@/components/drawitem.vue';
	Vue.use(DropdownMenu);
	Vue.use(DropdownItem);
	export default{
		components:{drawitem},
		props:['id','qi', 'year'],
		data(){
			return{
				curyear:'',
				yearlist:[],
				qishulist:[],
				curqishu:1,
				balllist:[],
				type:1,
				drawlist:[]
			}
		},
		watch: {
		    '$route'(to, from) {
				if(to.params.id){
					let id=to.params.id;
					this.getdata(id);
				}
		    },
			qi(val){
				this.curqishu=val;
				this.$nextTick(()=>{
					this.scrollToElement();
				})
			}
		},
		mounted() {
			this.getdata(this.id).then(res=>{
				this.curqishu=this.qi?this.qi:this.qishulist[0].qi;
				this.curyear=this.year?this.year:this.yearlist[0].year;
				this.$nextTick(()=>{
					this.scrollToElement();
				})
			});
		},
		methods:{
			getqi(item){
				if(item.qi.indexOf(this.year+'')>-1){
					return item.qi.replace(this.curyear,'')
				}else{
					return item.qi;
				}
			},
			scrollToElement() {
				let elem='qishu'+this.curqishu;
				const target = document.getElementById(elem);
			    if (target) {
					target.scrollIntoView({
					  behavior: "smooth",
					  block: "nearest",
					  inline: "center",
					});
			    }
			},
			getdata(id){
				return new Promise((reponse,reject)=>{
					this.$u.http("/api/getimg/getimggroup",{body:{id:id,year:this.curyear}}).then(res=>{
						this.qishulist=res.periodList;
						this.yearlist=res.yeargroup;
						reponse();
					})
				})
			},
			selyear(item){
				this.curyear=item.year;
				this.getdata(this.id).then(res=>{
					this.curqishu=this.qishulist[0].qi;
				});
				this.datachange();
			},
			selqishu(item,type){
				this.curqishu=item.qi;
				type==1&&this.$refs.item.toggle();
				this.datachange();
				this.scrollToElement();
			},
			datachange(){
				this.$emit('selqishu',{qi:this.curqishu,year:this.curyear});
			}
		}
	}
</script>

<style lang="scss">
.sticky_top{
	position: sticky;
	top: 2rem;
	background-color: #fff;
	z-index: 1111;
	min-height: 80px;
	.qishu_wrap{
		display: flex;
		padding: 8px 0;
		padding-top: 0;
		.qishu_morelist{
			display: flex;
			flex-wrap: wrap;
			gap: 0.5rem;
			padding: 20px 10px;
			.qishu_item{
				width: calc(calc(100% - 1rem)/3);
				padding: 6px 0;
				box-sizing: border-box;
				background-color: #f2f4f7;
				text-align: center;
				border-radius: 4px;
			}
			.active_qishu{
				background-color: $mcolor1;
				color: #fff;
			}
		}
		.qishu_list{
			width: 70%;
			white-space: nowrap;
			overflow-x:auto;
			margin-left: 0.5rem;
			.qishu_item{
				display: inline-block;
				padding: 4px 8px;
				background-color: #f2f4f7;
				text-align: center;
				margin-right: 0.5rem;
				border-radius: 4px;
			}
			.active_qishu{
				background-color: $mcolor1;
				color: #fff;
			}
		}
	}
	.year_list_wrap{
		overflow-x: auto;
		min-height: 35px;
		.year_list{
			max-width: 100vw;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			white-space: nowrap;
			padding-left: 1rem;
			.year_item{
				margin-right:  1.2rem;
				padding: 0.4rem 0;
				color: #999;
				font-weight: 700;
			}
			.active_year{
				color: #333;
			}
		}
	}
}
</style>