<template>
	<div class="mainpage" style="min-height: 100vh;height: auto;padding-bottom: 60px;    background: #f8f8f8;">
		<div class="top_nav">
			<div class="top_item" :class="{active_topnav:query.type==1}" @click="selnav(1)">网址大全</div>
			<div class="top_item" :class="{active_topnav:query.type==2}" @click="selnav(2)">担保平台</div>
		</div>
		<van-search
		  v-model="kw"
		  show-action
		  label=""
		  shape="round"
		  :background="mcolor"
		  placeholder="请输入搜索关键词"
		  @search="onSearch"
		>
		  <template #action>
		    <div @click="onSearch" style="color: #fff;font-weight: bold;" class="font3">搜索</div>
		  </template>
		</van-search>
		<div class="top_nav dh_nav" v-if="query.type==1">
			<div class="top_item" :class="{active_topnav:query.id==item.id}" @click="seldh(item)" 
			 v-for="(item,index) in catelist" :key="index">{{item.name}}</div>
		</div>
		<div v-if="isloading" style="height: 100vh;padding: 10px 0;">
			<van-skeleton title :row="3" v-for="(item,index) in 5" style="margin-bottom: 10px;"/>
		</div>
		<div v-if="!isloading">
			<div class="sitelist_wrap" v-if="query.type==1">
				<div class="data_item" v-for="(item,index) in datalist" :key="index" @click="topage(item)">
					<van-image
						width="100%"
						height="50"
						radius="5px"
						:src="item.img"
						lazy-load
					/>
					<div style="margin-top: 0.2rem;">{{item.name}}</div>
				</div>
			</div>
			<div class="sitelist_wrap" v-if="query.type==2">
				<div class="data_item danbao_item" style="width: 100%;" v-for="(item,index) in datalist" :key="index" @click="topage(item)">
					<van-image
						width="100%"
						height="3.6rem"
						:src="item.img"
						lazy-load
					/>
					<div class="danbao_name">
						<div style="color: #444;font-size: 0.7rem;font-weight: bold;">{{item.name}}</div>
						<span style="color: #333;">点击访问<van-icon name="arrow" /></span>
					</div>
				</div>
			</div>
		</div>
		
		<tabbar/>
	</div>
</template>

<script>
	import Vue from 'vue';
	import {  Cell, CellGroup } from 'vant';
	
	Vue.use(Cell);
	Vue.use(CellGroup);
	export default{
		data(){
			return{
				catelist:[],
				datalist:[],
				kw:"",
				query:{
					id:"",
					keyword:"",
					type:1,
				},
				isloading:false,
			}
		},
		mounted() {
			this.getcatelist();
		},
		methods:{
			seldh(item){
				this.query.id=item.id;
				this.getdatalist();
			},
			selnav(type){
				this.datalist=[];
				this.query.type=type;
				this.getdatalist();
			},
			onSearch(){
				this.query.keyword=this.kw;
				this.getdatalist();
			},
			topage(item){
				if(item.url){
					window.open(item.url);
				}
			},
			getcatelist(){
				this.$u.http("/index/menu/getcate",{}).then(res=>{
					this.catelist=res.data;
					this.query.id=this.catelist[0].id;
					this.getdatalist();
				})
			},
			getdatalist(){
				let formdata=this.query;
				this.isloading=true;
				this.$u.http("/index/menu/getdatalist",{body:formdata}).then(res=>{
					this.datalist=res.data.data;
					this.isloading=false;
				})
			}
		}
	}
</script>

<style lang="scss">
	.top_nav{
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 20px 0;
		background-color: $mcolor3;
		color: #fff;
		.top_item{
			width: 26%;
			font-size: 0.7rem;
			text-align: center;
		}
		.active_topnav{
			position: relative;
			font-size: 0.8rem;
			font-weight: bold;
			&::after{
				content: "";
				position: absolute;
				bottom: -.36rem;
				background-color: #fff;
				width: .8rem;
				height: .18rem;
				left: 0;
				border-radius: .18rem;
				right: 0;
				margin: auto;
			}
		}
	}
	.dh_nav{
		background-color: #fff;
		color: $mcolor1;
		padding: 12px 0;
		padding-top: 6px;
		.active_topnav{
			font-size: 0.7rem;
			&::after{
				background-color: $mcolor1;
			}
		}
	}
	.sitelist_wrap{
		display: flex;
		flex-wrap: wrap;
		gap: 0.6rem;
		padding: 12px;
		.data_item{
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;
			font-size: 0.55rem;
			width: calc(calc(100% - 1.8rem)/4);
			
		}
		.danbao_item{
			filter: drop-shadow(0 .22rem .22rem rgba(0, 0, 0, .05));
			    border-radius: .16rem;
				overflow: hidden;
				.danbao_name{
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: space-between;
					background-color: #fff;
					padding: 0.5rem;
					font-size: 0.6rem;
				}
		}
	}
</style>