<template>
	<div class="mainpage">
		<div class="per_header">
			<div class="user_head">
				<van-image
					width="100%"
					height="100%"
					fit="cover"
					:src="configdata.logo" v-if="configdata.logo"
				/>
			</div>
			<div class="user_name_wrap" v-if="userinfo.username">
				{{userinfo.username}}
			</div>
			<div class="user_login_btn" v-if="!userinfo.username">
				<div class="user_btn login_btn" @click="tologin">登录</div>
				<div class="user_btn register_btn" @click="tologin">注册</div>
			</div>
		</div>
		<div class="per_body">
			<div class="user_iconlist card_wrap">
				<div class="user_icon_item" v-for="(item,index) in navlist" :key="index" @click="topage(item)">
					<div class="user_icon">
						<img :src="item.icon"/>
					</div>
					<div class="user_iconname">{{item.name}}</div>
					<div class="icon_badge" v-if="index==1&&count!=0"></div>
				</div>
			</div>
			<div class="user_setlist card_wrap">
				<div class="user_cellitem" v-for="(item,index) in setlist" :key="index" @click="topage(item)">
					<img :src="item.icon" class="cell_icon"/>
					<div class="cell_name">{{item.name}}</div>
					<div style="display: flex;align-items: center;">
						<div class="van-info--dot" v-if="index==3&&feedcount>0"></div>
						<van-icon name="arrow" color="#666666" size="16"/>
					</div>
				</div>
				<div class="user_cellitem" @click="showsetting">
					<img :src="require('@/assets/imgs/nav/5.png')" class="cell_icon"/>
					<div class="cell_name">平台设置</div>
					<van-icon name="arrow" color="#666666" size="16"/>
				</div>
			</div>
		</div>
		<div class="logout_wrap" @click="logout" v-if="userinfo.username">
			<van-button type="info" round plain block>退出登录</van-button>
		</div>
		
		<van-popup v-model="isshowset" class="set_popwrap"  :close-on-click-overlay="false">
			<div class="set_pop">
				<div class="plat_list">
					<div class="plat_item " :class="{active_plat:curtype==1}" @click="curtype=1">
						<div style="font-weight: bold;" class="font35">新港彩</div>
						<div class="font15">下期开奖时间</div>
						<div class="font1">{{kjinfo}}</div>
					</div>
					<div class="plat_item" :class="{active_plat:curtype==2}" @click="curtype=2">
						<div style="font-weight: bold;" class="font35">香港</div>
						<div class="font15">下期开奖时间</div>
						<div class="font1">{{kjinfo1}}</div>
					</div>
					<div class="plat_item" :class="{active_plat:curtype==3}" @click="curtype=3">
						<div style="font-weight: bold;" class="font35">澳门</div>
						<div class="font15">下期开奖时间</div>
						<div class="font1">{{kjinfo2}}</div>
					</div>
				</div>
			</div>
			<div class="platform-btn" @click="isshowset=false">确认</div>
		</van-popup>
		<tabbar :count="count"/>
	</div>
</template>

<script>
	import Vue from 'vue';
	import { Icon } from 'vant';
	import { Popup } from 'vant';
	import { Dialog } from 'vant';
	
	Vue.use(Popup);
	Vue.use(Icon);
	export default{
		data(){
			return{
				count:0,
				isshowset:false,
				show:false,
				navlist:[
					{name:"我的收藏",icon:require("@/assets/imgs/1.png"),path:"/collection"},
					{name:"我的评论",icon:require("@/assets/imgs/2.png"),path:"/comment"},
					{name:"我的关注",icon:require("@/assets/imgs/3.png"),path:"/follow"},
					{name:"我的点赞",icon:require("@/assets/imgs/4.png"),path:"/mylikes"},
				],
				setlist:[
					{name:"站内信息",icon:require("@/assets/imgs/nav/1.png"),path:"/sitemsg"},
					{name:"联系客服",icon:require("@/assets/imgs/nav/2.png"),path:"/service"},
					{name:"关于我们",icon:require("@/assets/imgs/nav/3.png"),path:"/aboutus"},
					{name:"意见反馈",icon:require("@/assets/imgs/nav/4.png"),path:"/feedback"},
				],
				userinfo:{},
				configdata:this.configdefault,
				kjinfo:"",
				kjinfo1:"",
				kjinfo2:"",
				curtype:1,
				timer:null,
				feedcount:0,
			}
		},
		activated(){
			this.getdata();
			this.$u.getconfig().then(res=>{
				this.configdata=res;
			})
			this.userinfo=this.$u.getitem('$uinfo')||{};
			this.getuinfo();
		},
		mounted() {
			this.getdata();
			this.$u.getconfig().then(res=>{
				this.configdata=res;
			})
			this.userinfo=this.$u.getitem('$uinfo')||{};
			this.getuinfo();
			
			this.getNewHkLottery(this.$u.domain+'/Index/getLotteryLatest?lotteryType=1',1)
			this.getNewHkLottery(this.$u.domain+'/Index/getLotteryLatest?lotteryType=2',2)
			this.getNewHkLottery(this.$u.domain+'/Index/getlotteryhk',0)
		},
		methods:{
			getdata(){
				let uinfo=this.$u.getitem('$uinfo')||{};
				if(!uinfo.token){
					return;
				}else{
					this.getunread();
				}
			},
			getunread(){
				let uinfo=this.$u.getitem('$uinfo')||{};
				this.$u.http("/api/getimg/getunread",{body:{token:uinfo.token}}).then(res=>{
					let feedcount = res.feedcount;
					this.feedcount=feedcount;
					this.count=res.data;
				})
			},
			getNewHkLottery(url,type) {
			  const reqUrl = url || 'https://wjwqjqkskk.811227.com/ajax/lotteryLatest.php'
			  fetch(reqUrl).then(res => {
			    res.json().then(data => {
			      const endTime = new Date(data.nextTime).getTime();
			      const v = data.k.split(',')
			
			      const qi = v[0]
			
			      const obj = {
			        1: '八',
			        2: '九',
			        3: '图',
			        4: '库',
			        5: '开',
			        6: '奖',
			        7: '快'
			      }
			
			      function isChineseChar(char) {
			          const reg = /[\u4e00-\u9fff]|[\u3400-\u4dbf]/;
			          return reg.test(char);
			      }
			
			
			      // const nextQiShu = v[8].slice(4);
			      const nextQiShu = v[8]
			
			      const nextMonth = v[9];
			      const nextDay = v[10];
			      const nextWeek = v[11];
			      const nextTime = v[12];
			
			      const typeMap = {
			        0: '新港彩',
			        1: '香港',
			        2: '澳门'
			      }
				  let curyear = nextQiShu.substring(0,4);
				  let nextdate = curyear+'年'+nextMonth+'月'+nextDay+'日 '+nextTime;
			      type==0&&(this.kjinfo=nextdate);
			      type==1&&(this.kjinfo1=nextdate);
			      type==2&&(this.kjinfo2=nextdate);
			    });
			  });
			},
			showsetting(){
				this.isshowset=true;
			},
			logout(){
				let self=this;
				Dialog.confirm({
				  title: '提示',
				  message: '确定退出吗',
				}).then(() => {
				    self.$u.removeitem('$uinfo');
				    self.userinfo={};
				}).catch(() => {
				    
				});
			},
			getuinfo(){
				let uinfo=this.$u.getitem('$uinfo')||{};
				if(!uinfo.token){
					return;
				}
				let formdata={
					token:uinfo.token
				}
				this.$u.http("/api/index/getuinfo",{body:formdata}).then(res=>{
					if(res.status==200){
						this.$u.setitem('$uinfo',res.data);
						this.userinfo=res.data;
					}else{
						this.$u.removeitem('$uinfo');
						this.userinfo={};
					}
				}).catch(err=>{
					
				})
			},
			tologin(){
				this.$u.to('/login');
			},
			loginsucc(){
				this.userinfo=this.$u.getitem('$uinfo')||{};
				this.getuinfo();
			},
			topage(item){
				if(item.path=='/follow'){
					return this.$u.toast("正在开发中")
				}
				let uinfo=this.$u.getitem('$uinfo')||{};
				if(!uinfo.token){
					return this.$parent.showlogin(this.loginsucc);;
				}
				if(item.path){
					this.$u.to(item.path);
				}
			}
		}
	}
</script>

<style lang="scss">
	.platform-btn {
		color: #fff;
		width: 100%;
		height: 1.7rem;
		line-height: 1.7rem;
		background-color: #3e73fe;
		border-radius: 1.6rem;
		text-align: center;
		font-size: .7rem;
	}
	.set_pop{
		text-align: center;
		width: 12rem;
		overflow: hidden;
		padding: 1rem;
		.plat_list{
			
		}
		.plat_item{
			width: 9rem;
			background-color: #e7e4e7;
			border-radius: 8px;
			margin: 0 auto;
			margin-top: 10px;
			padding: 16px;
			box-sizing: border-box;
			line-height: 1.5;
			transition: 0.2s;
		}
		.active_plat{
			width: 9.5rem;
			background-color: #3e73fe;
			color:#fff;
		}
	}
	.mainpage{
		font-size: 0.8rem;
		height: 100vh;
		background-color: #f2f4f7;
		.logout_wrap{
			position: fixed;
			left: 0;
			width: 100%;
			padding: 20px;
			bottom: 50px;
			left: 50%;
			transform: translateX(-50%);
			max-width: 488px !important;
		}
		.per_body{
			padding: 0.5rem;
			.user_setlist{
				padding: 0 0.6rem !important;
				.user_cellitem{
					display: flex;
					align-items: center;
					padding: 0.6rem 0;
					&:not(:last-child){
						border-bottom: 1px solid #e5e5e5;
					}
					.cell_icon{
						width: 0.9rem;
						height: 0.9rem;
					}
					.cell_name{
						flex: 1;
						width: 20px;
						font-size: 0.7rem;
						padding-left: 0.5rem;
						color: #333;
					}
				}
			}
			.card_wrap{
				background-color: #fff;
				border-radius: 0.5rem;
				margin-bottom: 0.5rem;
				padding:1rem 0.5rem ;
				padding-top:  0.5rem;
				box-shadow: 0 .08rem .16rem 0 rgba(0,0,0,.03);
			}
			.user_iconlist{
				display: flex;
				gap: 0.5rem;
				.user_icon_item{
					width: calc(calc(100% - 1.5rem)/4);
					text-align: center;
					position: relative;
					.user_icon{
						img{
							width: 1.8rem;
							height: 1.8rem;
						}
					}
					.user_iconname{
						text-align: center;
						font-size: 0.6rem;
					}
					.icon_badge{
						position: absolute;
						right: 15px;
						top: 5px;
						background-color: red;
						color: #fff;
						width: 10px;
						height: 10px;
						border-radius: 50%;
					}
				}
			}
		}
		.per_header{
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0.6rem;
			background-color: #ffffff;
			.user_head{
				width: 3rem;
				height: 3rem;
				img{
					width: 100%;
					height: 100%;
					border-radius: 5rem;
				}
			}
			.user_name_wrap{
				flex: 1;
				width: 20%;
				padding-left: 10px;
			}
			.user_login_btn{
				display: flex;
				align-items: center;
				font-size: 0.65rem;
				font-weight: bold;
				.user_btn{
					padding: 0.4rem 0.8rem;
					border-radius: 0.4rem;
					color: #fff;
					 background: var(--vi, linear-gradient(180deg, $mcolor1, $mcolor2));
					 margin-left: 0.5rem;
					 box-sizing: border-box;
					 border: 1px solid $mcolor1;
					 cursor: pointer;
				}
				.login_btn{
					
				}
				.register_btn{
					border: 1px solid $mcolor1;
					background: linear-gradient(180deg, hsla(0, 0%, 100%, .2), rgba(62, 115, 254, .2));
					color: $mcolor1;
				}
			}
		}
	}
</style>